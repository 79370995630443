@import '../../variables.scss';

.authenticate-msg {
    background-color: $blue;
    color: $white;
    margin-top: 50%;
    justify-content: center;
    margin: 100px auto;
    width: 500px;
    padding: 6%;
    border-radius: 10px;
    .logout-button{
        margin-top: 2em;
        right: auto;    
    }
}