@import '../../variables.scss';

.virtual-appliance {
    min-height: 80vh;
    min-width: 800px;
    padding: 30px 40px;
    width: 100%;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 0 14px 0 rgba(31, 53, 77, 0.2);

    .table-header{
        color: #2E3F55;
        font-family: roboto-bold;
        font-size: 18px;
        line-height: 25px;
        padding-bottom: 20px;
        padding-left: 10px;
        border-bottom: 1px solid rgba(187, 187, 187, .5);

    }
    .tbl-list {
        width: 100%;

        .tbl-body {
            font-size: 12px;
            .tbl-row {
                padding: 15px;
                color: $table-body-text;
                font-family: roboto-bold;
                border-bottom: 1px solid rgba(187, 187, 187, .5);
                line-height: 40px;

                td {
                    text-align: left;
                    font-weight: 500;
                    padding-left: 10px;

                    // &>div {
                    //     display: inline-block;
                    // }
                }

                &.inactive{
                    color: #bbbbbb;
                }
            }
            .status {

				&.active {
					color: $active;
                }
               
			}
            .rp-name{
                width: 35%;
                .dataset-element {
					max-width: 90%;
					// display: inline-block;
					overflow: hidden;
                    text-overflow: ellipsis;
                   white-space: nowrap;
				}
            }
            .time{
                font-style: italic;
                color: #909090;
            }

        }
    }
}

.email-popover {
	width: auto;
	max-width: 100%;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	.email-popover-content {
		
		.email-icon {
			padding-right: 8px;
		}

		.text {
			color: $save-btn-color;
			font-family: roboto-regular;
			letter-spacing: 0;
			line-height: 19px;
		}

		.inactive-email {
			color: $inactive;
			font-style: italic;
		}
	}
}