//Colors
$white: #ffffff;
$off-white: #f8f9fb;
$light-grey: #384047;
$blue: #035b7f;
$button-blue : #00CEFF;   
$new-Button-blue: #00B5E0;
// $blue-button-text-color: #ACF0FF;
$blue-button-text-color: #FFF;
$navy-blue: #035e82;
$success-green: #17DF6D;
$button-text-color: #057CAB;
$toaster-txt-success: #e9fff2;
$error-red: #FF5F5F;        // button red
$toaster-txt-error: #FFE7E7;
$button-color: rgb(8, 248, 248);
$spinner-color: #61dafb;
$login-bgcolor: #035b7f;
$login-text: #324054;
$forget-bgcolor: #035b7f;
$forget-text: #324054;
$input-bgcolor: #E8EEEF;
$input-border: #EFEFEF;
$save-btn-color: #00CEFF;
$cancel-btn-color: #D8D8D8;
$cancel-btn-text: #646464;
$pagination-text: #AEAEAE;
$active-page: #035e82;
$alert-red: #f00;
$highlight: #D1F2EB;
$color-grey: #324054;
$active-grey: #EDEDED;  

$active-VA: #01304b;
$active-noti: #012c44;
$active-sidenav: #003655;
$active-link: #446D82;
$active-subheading: #034161;
$logo-sidenav: #5fd770;
$sidenav-icon-bg: #003758;
$black: #011115;
//modal 
$header-text: #324054;
$modal-footer-bg: #EDEDED;
$modal-label-color: #9C9C9C;
$active-nav-link: #2E3F55;
$gray-border: #979797;

//table
$grid-header: #f8f9fb;
// $tbl-header-txt: #8a8a8a;
$tbl-header-txt: #B1B1B1;
$table-body-text: #2e3f55;
$active: #17df6d;
$inactive: #BBBBBB;
$tbl-label-color: #424242;
$active-ds: #02E5FF;
$ds-content: #444A6F;