
@import '../../variables.scss';

.pdf-document-container {
    padding: 10px;
    .react-pdf__Document {
        display: flex;
        flex-direction: column;
        align-items: center;

        .document-pages{
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.48);
            margin-bottom: 10px;
            // .react-pdf__Page{
            //     text-align: center;
            // }
            .page-number{
                text-align: center;
                color: $table-body-text;
                padding: 10px;
            }
        }
    }
}