@import '../../variables.scss';

#main {
	height: 100vh;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $light-grey;
	background: $forget-bgcolor;
	.forget-container {
		background-color: $white;
		width: 500px;
		padding: 6% 10%;
		border-radius: 5px;
		.forget {
			height: auto;
			.forget_header {
				color: $forget-text;
				text-align: center;
				line-height: 1.5em;
				margin-bottom: 0.8em;
				margin-top: 0.2em;
				.logo_img {
					width: 250px;
					margin-bottom: 50px;
					margin-left: -30px;
				}
				.text {
					display: block;
					font-family: roboto-light;
					font-size: 30px;
				}
			}
			.form-control:focus {
				box-shadow: none;
			}

			input[type='text'],
			input[type='password'] {
				color: $light-grey;
				background-color: $input-bgcolor;
				border: none;
				border-radius: 5px;
				padding: 1em;
				margin-bottom: 1.2em;
				width: 100%;
				outline: none;
				border: 1px solid transparent;
			}
			input[type='text']:focus,
			input[type='password']:focus {
				background-color: $white;
				border-color: $input-border;
				outline: none;
				border: 1px solid $header-text;
				border-radius: 5px;
			}

			.msg_field {
				text-align: left;
				height: 60px;
				height: 60px;
				color: rgba(56, 64, 71, .5);
				text-align: center;
				.error {
					color: $error-red;
				}
			}
			.submit {
				margin-bottom: 3em;
			}

			.button {
				// color: $button-color;
				// border-color: $button-color;
				// background-color: $white;
				border-width: 1px;
				margin-top: 20px;
				padding: 1em;
				// border-radius: 50px;
				width: 100%;
				font-family: roboto-bold;
			}
			// .button:hover {
			// 	color: $light-grey;
			// 	border-color: $button-color;
			// 	background-color: $button-color;
			// }

			.forgot {
				font-weight: 500;
				color: $forget-text;
				line-height: 1.5em;
				margin-bottom: 2em;
				margin-top: 0.2em;
				margin-left: '10px';
				text-decoration: underline;
				text-align: center;
				cursor: pointer;
			}
		}
	}
}
