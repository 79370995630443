@import '../../variables.scss';

.document-container {
    display: flex;
    height: 100%;
    background-color: $off-white;

    .doc-left-content {
        width: 20%;
        padding: 30px 0px;
        border-right: 1px solid #CAC8C8;

        .study-name{
            padding: 8px 25px;
            color: #9A9A9A;  
            font-size: 18px;
            font-weight: bold;
            margin: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .study-document-name{
            padding: 8px 25px;
            margin-bottom: 5px;
            color: #2E3F55;
            font-size: 18px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .overview {
            color: $table-body-text;
            font-size: 18px;
            padding: 10px 25px;
            border-radius: 4px;
            background-color: $active-grey;
        }

        .subheader-section {
            // padding-left: 20px;
            cursor: pointer;

            .subheader {

                h3,
                h3>span {
                    padding: 0;
                    margin: 0;
                    font-size: 16px ;
                    font-weight: normal;
                }

                h4 {
                    padding: 0;
                    padding-left: 15px;
                    margin: 0;
                }

                h4,
                h4>span {
                    font-size: 14px ;
                    font-weight: normal;
                }

                font-size: 16px;
                padding: 10px 10px 10px 40px;
                color: #2E3F55;

                &.active {
                    border-radius: 4px;
                    background-color: $active-grey;

                    h3>span,
                    h4>span {
                        font-weight: bold
                    }

                    ;
                }
            }
        }
    }

    .doc-right-content {
        width: 80%;
        // overflow:auto;
        overflow-y: scroll;
        // background-color: $white;
        padding: 30px;
        word-wrap: break-word;

        .scroll-top{
            color: blue;
            text-decoration: underline;
            align-items: right;
            float: right;
            padding-right: 10px;

            .scroll-to-top{
                cursor: pointer;
            }
        }
    }
}

.doc-popover {
	width: auto;
	max-width: 100%;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	.doc-popover-content {
		.text {
			color: $save-btn-color;
			font-family: roboto-regular;
			letter-spacing: 0;
			line-height: 19px;
		}
	}
}