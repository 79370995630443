@import './../../variables.scss';

.notification-container {
	background-color: $off-white;
	padding: 20px 3% 0;

    .no-more-records{
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $pagination-text;
    }
    .loading-list {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        .loading {
            height: 24px;
            width: 65px;
            color: #324054;
            font-size: 12px;
            font-family: roboto-bold;
            // font-weight: bold;
            line-height: 17px;
        }

        .dot-pulse {
            position: relative;
            top: 15px;
            left: -10048px;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: #5FD770;
            box-shadow: 9999px 0 0 -5px #5FD770;
            animation: dotPulse 1.5s infinite linear;
            animation-delay: .25s;
        }

        .dot-pulse::before,
        .dot-pulse::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: #5FD770;
        }

        .dot-pulse::before {
            box-shadow: 9989px 0 0 -5px #5FD770;
            animation: dotPulseBefore 1.5s infinite linear;
            animation-delay: 0s;
        }

        .dot-pulse::after {
            box-shadow: 10010px 0 0 -5px #5FD770;
            animation: dotPulseAfter 1.5s infinite linear;
            animation-delay: .5s;
        }

        @keyframes dotPulseBefore {
            0% {
                box-shadow: 9989px 0 0 -5px #5FD770;
            }

            30% {
                box-shadow: 9989px 0 0 2px #5FD770;
            }

            60%,
            100% {
                box-shadow: 9989px 0 0 -5px #5FD770;
            }
        }

        @keyframes dotPulse {
            0% {
                box-shadow: 9999px 0 0 -5px #5FD770;
            }

            30% {
                box-shadow: 9999px 0 0 2px #5FD770;
            }

            60%,
            100% {
                box-shadow: 9999px 0 0 -5px #5FD770;
            }
        }

        @keyframes dotPulseAfter {
            0% {
                box-shadow: 10010px 0 0 -5px #5FD770;
            }

            30% {
                box-shadow: 10010px 0 0 2px #5FD770;
            }

            60%,
            100% {
                box-shadow: 10010px 0 0 -5px #5FD770;
            }
        }
    }

    .notifications {

        .notification-info-read {
            background-color: white;
            box-shadow: 0 0 14px 0 rgba(31, 53, 77, 0.2);
            border-radius: 5px;

        }

        .notification-info-unread {
            background-color: transparent;
            border-bottom: 1px solid #BBBBBB;
        }

        .notification-info {
            width: 100%;
            padding: 15px 20px;
            color: #324054;
            margin-bottom: 10px;
        }

        .notification-title {
            position: relative;
            margin-bottom: 5px;

            .notification-for {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;
                display: inline-block;
                color: #324054;
                font-family: 'roboto-bold';
            }

            .notif-datetime {
                position: absolute;
                right: 10px;
                color: #909090;
                font-size: 14px;
            }
        }

        .notification-msg {
            position: relative;
            font-size: 14px;

            .toast-icon {
                height: 25px;
                width: 25px;
                border-radius: 18px;
                margin-right: 10px;
                color: #ffffff;
                padding: 5px;
                font-size: 15px;
            }

            // .icon-type-available {
            //     background-color: $active-ds;
            //     -webkit-text-stroke: 0.8px $active-ds;
            // }

            .icon-type-available{
                background-color: #dfdfdf;
                color: #324054;
                -webkit-text-stroke: 0.8px #dfdfdf;
            }
            .icon-type-acquisition_requested, .icon-type-acquiring{
                border: 1px solid $navy-blue;
                padding: 4px;
                color: $navy-blue;
                background-color: #fff;
                -webkit-text-stroke: 0.8px #fff;

            }
            .icon-type-rejected{
                border: 1px solid #324054;
                color: #324054;
                padding: 5px 8px;
                background-color: #fff;
                -webkit-text-stroke: 0.8px #fff;

            }
            .icon-type-inactive{
                color: #fff;
                padding: 5px 8px;

                background-color: #324054;
                -webkit-text-stroke: 0.8px #324054;

            }
            .icon-type-active{
                color: #fff;
                background-color: $navy-blue;
                -webkit-text-stroke: 0.8px $navy-blue;
            }
            .icon-type-processing{
                border: 1px solid $new-Button-blue;
                padding: 4px;
                color: $new-Button-blue;
                -webkit-text-stroke: 0.8px #fff;
            }
            .icon-type-error,.icon-type-acquisition_failed {
                font-size: 12px;
                padding: 5px 9px;
                -webkit-text-stroke: 0.8px $error-red;
                background-color: $error-red;
            }

            .icon-type-processed {
                background-color: $new-Button-blue;
                -webkit-text-stroke: 0.8px $new-Button-blue;
            }

            .notif-message {
                font-size: 18px;
            }

            .notif-link {
                position: absolute;
                right: 10px;
                cursor: pointer;
                color: #00CEFF;
                text-decoration: underline;
            }
        }
    }

}