@import './variables.scss';
@import './typography.scss';

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

body,
html {
	height: 100%;
	width: 100%;
	font-family: roboto-regular;
	font-size: 12px;
}

.overlay {
	position: absolute;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(60, 60, 60, .5);
	z-index: 9998;
	display: flex;
	justify-content: center;
	align-items: center;

	.spinner {
		color: $spinner-color;
		font-size: 50px;
		animation: spin infinite 1s linear;
	}
}

.sa-container {
	display: flex;
	width: 100%;
	height: auto;
	align-items: stretch;

	&>.left-panel {
		// width: 20%;
		display: inline-block;
		background: $blue;
		background: #0A3B5C;//linear-gradient(0deg, rgba(4, 48, 76, 1) 0%, rgba(3, 94, 130, 1) 100%);
	}

	&>.right-panel {
		width: 100%;
		background-color: $white;
		overflow: scroll;
		// padding: 20px 2% 0;
		height: 100vh;
		// background-color: $off-white;
		// overflow-x: scroll;
		overflow-x: hidden;
	}

	.document-panel {
		padding: 0px;
		overflow: hidden;
	}
}

.Toastify {

	.Toastify__toast-container {
		width: 550px;
	}

	.Toastify__toast {
		cursor: default;
	}

	.success-ds {
		background-color: white !important;
		color: black;
		width: 750px;
		border-radius: 5px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.48);
		padding: 15px 5px 15px 18px;
		font-weight: 600;
		min-height: 40px;

		.Toastify__close-button {
			color: black;
		}

		.Toastify__toast-body {
			margin: 0
		}

		.record-text {
			font-size: 12px;
			padding-top: 10px;

			.view-link {
				cursor: pointer;
				padding-left: 8px;
				color: $button-blue;
				text-decoration: underline;
			}
			.hide-view-link{
				visibility: hidden;
			}
		}

		.toast-msg-container,
		.toast-msg-container-viewall {
			// min-height: 70px;
			min-height: 30px;
			max-height: 69px;
			overflow-y: hidden;

			.toast-msg {
				margin-bottom: 5px;
			}

			.capitalise {
				text-transform: capitalize;
			}

			.toast-icon {
				height: 18px;
				width: 18px;
				border-radius: 13px;
				margin-right: 10px;
				color: $white;
				padding: 4px;
				font-size: 10px;
			}

			.available-icon {
				background-color: #dfdfdf;
				color: #324054;
			}

			.acquisition-icon {
				border: 1px solid $navy-blue;
				padding: 3px;
				color: $navy-blue;
				background-color: #fff;
			}

			.rejected-icon {
				border: 1px solid #324054;
				color: #324054;
				background-color: #fff;
				font-size: 10px;
				padding: 3px 6px;
				-webkit-text-stroke: 0.8px #fff;
			}

			.inactive-icon {
				color: #fff;
				background-color: #324054;
				font-size: 10px;
				padding: 4px 7px;
				-webkit-text-stroke: 0.8px #324054;
			}

			.active-icon {
				color:  $blue-button-text-color; // #057cab;
				background-color: $navy-blue;// #00ceff;
			}

			.processed-icon {
				background-color: $new-Button-blue;
				color: $white;
			}

			.processing-icon {
				border: 1px solid $new-Button-blue;
				padding: 3px;
				color: $new-Button-blue;
				background-color: #fff;
			}

			.error-icon {
				font-size: 10px;
				padding: 4px 7px;
				-webkit-text-stroke: 0.8px $error-red;
				background-color: $error-red;
			}
		}

		.toast-msg-container-viewall {
			overflow-y: scroll;
			min-height: 30px;
			max-height: 135px;

			&::-webkit-scrollbar {
				width: 6px;
				padding-left: 5px;
				background: transparent;
			}

			&::-webkit-scrollbar-thumb {
				width: 6px;
				border-radius: 3px;
				padding-left: 5px;

				background-color: #D8D8D8;
			}
		}
		.Toastify__close-button{
			position: absolute;
			right: 20px;
		}
	}

	.toast-notification {
		border-radius: 3px;
		text-align: center;
		width: 500px;
		min-height: 40px;
	}

	.success {
		color: $success-green;
		background-color: $toaster-txt-success;
		border: 2px solid $success-green;
		font-weight: 600;

		.Toastify__close-button {
			color: $success-green;
		}
	}

	.error {
		color: $error-red;
		background-color: $toaster-txt-error;
		border: 2px solid $error-red;
		font-weight: 600;

		.Toastify__close-button {
			color: $error-red;
		}
	}
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.delete-modal-overlay {
	z-index: 1050 !important;
}

.delete-modal {
	width: 450px !important;

	.modal-content {
		border-radius: 5px;
		.modal-header {
			border: none;
			justify-content: center;
			color: $header-text;
			font-family: roboto-light;
			font-size: 30px;
			font-weight: 300;
			letter-spacing: 0;
			line-height: 39px;
			padding: 20px;
			padding-top: 54px;
		}

		.modal-body {
			border: none;
			color: $active-nav-link;
			font-family: roboto-medium;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 14px;
			text-align: center;
			padding: 20px;
		}
	}

	.modal-footer {
		display: flex;
		border: none;
		// align-items: center;
		// justify-content: center;
		padding: 20px 45px;
		padding-bottom: 63px;

		.buttons {
			box-sizing: border-box;
			height: 38.56px;
			width: 175px;
			// border-radius: 23.13px;
			font-size: 11px;
		}

		.cancel-btn {
			margin-right: 23px;
		}
	}
}

strong {
	font-family: 'roboto-bold';
}