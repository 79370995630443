@import '../../variables.scss';

.list-wrapper {
    padding: 40px 20px 0px 0px;

    .header {
        color: $header-text;
        font-family: roboto-medium;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 26px;
    }

    .list {
        position: relative;

        .activity {
            position: relative;
            padding-bottom: 25px;

            .bullets {
                height: 10px;
                width: 10px;
                border-radius: 5px;
                position: absolute;
                left: 10px;
                margin-top: 5px;
            }

            .line {
                background: $active-page;
                width: 1px;
                height: 100%;
                position: absolute;
                left: 15px;
                margin-top: 15px;
            }

            .available {
                background-color: $inactive;
            }

            .acquisition-started, .acquisition-complete {
                background-color: $white;
                border: 2px solid $navy-blue;
            }

            // .acquisition-complete {
            //     background-color: $navy-blue;
            // }

            .acquisition-failed,
            .processing-failed {
                background-color: #FF5F5F
            }

            .processing-started {
                background-color: $white;
                border: 2px solid $new-Button-blue;
            }

            .processing-complete {
                background-color: $new-Button-blue;
            }

            .activated {
                background-color: $navy-blue;
            }

            .inactivated {
                background-color: $header-text;
            }

            .rejected {
                background-color: $white;
                border: 2px solid $header-text;
            }

            .activity-description {
                margin-left: 40px;
                color: $ds-content;
                font-family: roboto-regular;
                word-wrap: break-word;

                .time {
                    font-family: 'roboto-medium';
                    font-weight: 500;
                }
                .sign-icon{
                    margin: 0px 0px 5px 5px;
                    cursor: pointer;
                }
            }
        }
    }
}

.image {
    width: 100%;
}