@import '../../variables.scss';

.researchpartner-modal {
  // width: 50%;
  // height: 719px;
  max-width: none !important;
  width: 700px !important;

  .modal-content {
    .modal-header {
      border: none;
      color: $header-text;
      font-family: roboto-light;
      font-size: 30px;
      font-weight: 300;
      justify-content: center;
      padding: 30px;
    }

    .modal-body {
      .nav-tabs-update {
        border-bottom: none;
        height: 38px;
        border-radius: 5px;
        justify-content: center;

        .nav-link {
          font-family: roboto-medium;
          font-size: 12px;
          background-color: $modal-footer-bg;
          text-align: center;
          padding: 10px 30px;
          width: 300px;
          color: $active-nav-link;
          // text-transform: uppercase;

          &.active {
            background-color: $active-nav-link;
            color: $white;
            font-family: roboto-bold;
          }

          &:first-child {
            border-radius: 5px 0 0 5px
          }

          &:last-child {
            border-radius: 0px 5px 5px 0px;
          }
        }

        .nav-link:focus {
          background-color: $active-nav-link;
          color: $white;
        }
      }

      .nav-tabs-add {
        border-bottom: none;
        height: 38px;
        border-radius: 5px;
        justify-content: center;

        .nav-link {
          font-family: roboto-medium;
          font-size: 12px;
          background-color: $modal-footer-bg;
          text-align: center;
          padding: 10px 30px;
          width: 600px;
          color: $active-nav-link;
          // text-transform: uppercase;
          border-radius: 5px;

          &.active {
            background-color: $active-nav-link;
            color: $white;
            font-family: roboto-bold;
          }
        }

        .nav-link:focus {
          background-color: $active-nav-link;
          color: $white;
        }

      }

      .modal-tabs {

        .tab-content {
          height: 20em;
          padding: 0px 40px;
          margin: 40px 0;
          justify-content: center;

          .userDetail {
            padding: 0 10px;
          }

          .section {
            justify-content: center;
            margin-bottom: 15px;

            .labels {
              display: flex;
            }

            .inputfields {
              display: flex;
            }

            label {
              color: $modal-label-color;
              font-family: roboto-medium;
              font-size: 12px;
              line-height: 16px;
              outline: none;
              width: 50%;
              // text-transform: uppercase;
            }

            input,
            textarea {
              box-sizing: border-box;
              margin-right: 2em;
              border: 1px solid rgba(156, 156, 156, .5);
              border-radius: 5px;
              background-color: $white;
              box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
              height: 40px;
              width: 50%;
              padding-left: 10px;
            }

            .checkbox-contain{
            input[type=checkbox] {
              height: 18px;
              width: 14px;
              margin-left: 84px;
              margin-right: 25px;
              outline: none !important;
              border: none !important;
              box-shadow: none;
            }
              input[type=checkbox]:before {
                content: "\2714";
                border: 0.5px solid $gray-border;
                border-radius: 2px;
                margin-right: 10px;
                vertical-align: center;
                color: transparent;
                padding: 0 1.2px;
              }

              input[type=checkbox]:checked:before {
                border: 0.5px solid $success-green;
                  border-radius: 2px;
                  margin-right: 10px;
                  vertical-align: center;
                  color: $white;
                  background-color: $success-green;
                  padding: 0 1.2px;
              }
            }

            .sqs-input {
              width: 55%;
              margin: 0;
            }

            textarea {
              width: 100%;
              margin-right: 0;
              height: 75px;
              padding: 5px 10px;
            }

            input:focus,
            textarea:focus {
              outline: none;
            }

            .delete_field {
              text-align: left;
              margin-bottom: 30px;
            }

            .deleteresearchpartner {
              height: 16px;
              color: $error-red;
              font-family: roboto-regular;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
              text-align: center;
              text-decoration: underline;
              cursor: pointer;
            }

            .custom-search-select {
              width: 55%;
              height: 32px;
              display: inline-block;

              &:hover {
                border: none;
              }
            }
          }

          .setting {
            padding-bottom: 25px;
            border-bottom: 0.5px solid $gray-border;

            .custom-status-select {
              width: 187px;
              height: 32px;
              display: inline-block;
              margin-left: 80px;
            }
          }

          .error_field {
            // padding: 10px 0px;

            span {
              color: $error-red;
            }
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      // height: 155px;
      background-color: $modal-footer-bg;
      // align-items: center;
      // justify-content: center;
      padding: 0px 60px;
    }
  }
}