@import '../../variables.scss';

.adduser-modal {
  max-width: none !important;
  width: 700px !important;

  .modal-content {
    .modal-header {
      border: none;
      color: $header-text;
      font-family: roboto-light;
      font-size: 30px;
      font-weight: 300;
      justify-content: center;
      padding: 30px;
    }

    .modal-body {
      .nav-tabs-update {
        border-bottom: none;
        height: 38px;
        border-radius: 5px;
        justify-content: center;

        .nav-link {
          font-family: roboto-medium;
          font-size: 12px;
          background-color: $modal-footer-bg;
          text-align: center;
          padding: 10px 30px;
          width: 150px;
          color: $active-nav-link;
          // text-transform: uppercase;

          &.active {
            background-color: $active-nav-link;
            color: $white;
            font-family: roboto-bold;
          }

          &:first-child {
            border-radius: 5px 0 0 5px
          }

          &:last-child {
            border-radius: 0px 5px 5px 0px;
          }
        }

        .nav-link:focus {
          background-color: $active-nav-link;
          color: $white
        }

      }

      .modal-tabs {

        .tab-content {
          height: 12em;
          padding: 0px 40px;
          margin: 40px 0;
          justify-content: center;

          .userDetail {
            padding: 0 10px;
          }

          .section {
            justify-content: center;
            margin-bottom: 20px;

            .labels {
              display: flex;
            }

            .inputfields {
              display: flex;
            }

            label {
              color: $modal-label-color;
              font-family: roboto-medium;
              font-size: 12px;
              line-height: 16px;
              outline: none;
              width: 50%;
              // text-transform: uppercase;
            }

            input,
            textarea {
              box-sizing: border-box;
              border: 1px solid rgba(156, 156, 156, .5);
              border-radius: 5px;
              background-color: $white;
              box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
              height: 40px;
              width: 50%;
              padding-left: 10px;
            }

            .inputs {
              margin-right: 2em;
            }

            .label-margin {
              margin-right: 25px;
            }

            input:focus,
            textarea:focus {
              outline: none;
            }

            .deleteuser {
              height: 16px;
              width: 62px;
              color: $error-red;
              font-family: roboto-regular;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
              text-align: center;
              text-decoration: underline;
              cursor: pointer;
            }

            .org-label {
              width: 30%;
            }

            .custom-search-select {
              width: 400px;
              height: 32px;
              display: inline-block;
            }
          }

          .setting {
            padding-bottom: 10px;
            border-bottom: 0.5px solid $gray-border;

            .custom-status-select {
              width: 187px;
              height: 32px;
              display: inline-block;
              margin-left: 80px;
            }
          }

          .roles {
            justify-content: center;
            margin-left: 20px;

            .labels {
              display: flex;

              .rolelabel {
                // text-transform: uppercase;
                width: 20%;
                width: 115px;

              }

              .rightrolebox {
                // display: inline-block;
                margin-left: 30px;
                width: 70%;

                .check-label {
                  color: $active-nav-link;
                  font-family: roboto-regular;
                  font-size: 12px;
                  letter-spacing: 0;
                  // line-height: 34px;
                  width: 190px;
                }

                input[type=checkbox]+label {
                  margin-bottom: 10px;
                }

                input[type=checkbox] {
                  display: none;
                }

                input[type=checkbox]+label:before {
                  content: "\2714";
                  border: 0.5px solid $gray-border;
                  border-radius: 2px;
                  margin-right: 10px;
                  vertical-align: center;
                  color: transparent;
                  padding: 0 1.2px;
                }

                input[type=checkbox]:checked+label:before {
                  border: 0.5px solid $success-green;
                  border-radius: 2px;
                  margin-right: 10px;
                  vertical-align: center;
                  color: $white;
                  background-color: $success-green;
                  padding: 0 1.2px;

                }

                textarea {
                  &:focus {
                    border: none;
                  }

                  width: 300px;
                }
              }
            }

            label {
              color: $modal-label-color;
              font-family: roboto-medium;
              font-size: 12px;
              line-height: 16px;
              outline: none;
              width: 35%;
            }
          }

          .error_field {
            padding: 10px 0px;

            span {
              color: $error-red;
            }
          }

          .error_org {
            margin-top: 130px;

            span {
              padding: 0px;
            }
          }

          .error_role {
            margin-top: 70px;

            span {
              padding: 0px;
            }
          }

          .adduser-btn {
            height: 38.56px;
            width: 191px;
            font-size: 12px;
            margin-right: 20px;
            letter-spacing: 0;
            line-height: 19px;
            text-align: center;
            font-family: roboto-bold;
            margin-left: 80px;
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      // height: 155px;
      background-color: $modal-footer-bg;
      // align-items: center;
      // justify-content: center;
      padding: 0px 60px;

    }
  }
}