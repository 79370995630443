@import '../../variables.scss';

.dataset-container {
	border: 1px solid #bbbbbb;
	border-radius: 5px;
	padding: 20px 30px;
	position: relative;
	// box-shadow: 2px 7px 10px 0 rgba(0,0,0,0.23);

	margin-bottom: 40px;
	background-color: #f8f9fb;
	color: #bbbbbb;
	font-size: 14px;

	&.active {
		background-color: #fff;
		color: #11171e;
		cursor: pointer;
		box-shadow: 0 0 14px 0 rgba(31,53,77,0.2);
		border: none;
	}

	.status {
		position: absolute;
		top: -16px;
		left: 28px;
		border-radius: 5px;
		height: 30px;
		width: 120px;
		display: grid;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		line-height: 17px;
		font-family: "roboto-bold";

		&.type_1 {
			background-color: #dfdfdf;
			color: #324054;
		}

		&.type_2 {
			// border: 1px solid #00ceff;
			// color: #00ceff;
			// background-color: #fff;
			border:1px solid $navy-blue;
			color: $navy-blue;
			background-color: $white;
			font-style: italic;
		}

		&.type_7 {
			// border: 1px solid #00ceff;
			// color: #00ceff;
			// background-color: #fff;
			border:1px solid $navy-blue;
			color: $navy-blue;
			background-color: $white;
			font-style: italic;
			width: 200px;
		}

		&.type_3 {
			background-color: #ff5f5f;
			color: #fff;
		}

		&.type_4 {
			// background-color: #02e5ff;
			// color: #057cab;
			// border: 1px solid $new-Button-blue;
			// color: $blue-button-text-color;
			// background-color: $new-Button-blue;
			border: 1px solid $navy-blue;
			color: $blue-button-text-color;
			background-color: $navy-blue;
		}

		&.type_5 {
			border: 1px solid #324054;
			color: #324054;
			background-color: #fff;
		}

		&.type_6 {
			color: #fff;
			background-color: #324054;
		}
	}

	.acquiring-progressbar {
		padding-top: 10px;

		.progress {
			height: 8px;
		}

		.acquiring-progress .progress-bar {
			// background-color: #00ceff;
			background-color: $navy-blue;
			border-radius: 5px;
		}

		.progress-bar-striped {
			background-image: linear-gradient(45deg,
					rgba(255, 255, 255, 0.15) 25%,
					transparent 25%,
					transparent 50%,
					rgba(10, 0, 0, .15) 50%,
					rgba(0, 0, 0, .15) 75%,
					#00150400 75%,
					#00150400);
		}
	}

	.dataset-info {
		display: flex;
		padding-top:15px;

		.left-panel100 {
			width: 100%;

			.loading-icon {
				height: 50px;
				width: 50px;
				display: inline-block;
				animation: spin 2s infinite linear;
				border-radius: 50%;
			}

			.dataset-title {
				font-size: 26px;
				line-height: 32px;
				font-family: 'roboto-bold';
				color: #bbbbbb;

				.title-type-4 {
					color: #324054;
				}
			}

			.dataset-error {
				color: #ff5f5f;
				font-size: 15px;
			}

			.dataset-detail {
				display: flex;
				align-items: flex-start;
				padding-top: 10px;
				width: 80%;

				.dataset-description {
					width: 65%;
					border-right: 1px solid #bbbbbb;
					margin-right: 30px;
					padding-right: 30px;
				}

				.heading {
					color: #bbbbbb;
					font-family: 'roboto-medium';
					width: 100px;
					font-size: 12px;
				}

				.detail {
					padding-top: 15px;
				}

				.dataset-date-container {
					width: 35%;
				}

				.dataset-date {
					display: flex;
					padding-bottom: 3px;
					font-size: 12px;
					align-items: center;
					.date-heading {
						color: #bbbbbb;
						width: 90px;
					}
				}

				.detail-date {
					padding-left: 10px;
					font-size: 14px;
				}
			}

			.dataset-studies {
				background-color: #f8f9fb;
			}

			.dataset-sign-info {
				padding-top: 10px;
				font-size: 12px;

				.desc-date {
					font-weight: bold;
					color: #9c9c9c;
					padding-right: 10px;
				}
			}
		}

		.left-panel {
			width: 80%;

			.loading-icon {
				height: 40px;
				width: 40px;
				display: inline-block;
				animation: spin 2s infinite linear;
				margin-left: -8px;
				border-radius: 50%;
			}

			.dataset-title {
				font-size: 26px;
				line-height: 32px;
				font-family: 'roboto-bold';
				color: #bbbbbb;

				.title-type-4 {
					color: #324054;
				}
			}

			.dataset-error {
				color: #ff5f5f;
				font-size: 15px;
			}

			.dataset-detail {
				display: flex;
				align-items: flex-start;
				padding-top: 15px;

				.dataset-description {
					width: 65%;
					border-right: 1px solid #bbbbbb;
					margin-right: 30px;
					padding-right: 20px;
				}

				.heading {
					color: #bbbbbb;
					font-size: 12px;

					.heading-desc {
						display: inline-flex;
						min-width: 96%;
					}

					.fa-pencil-alt {
						cursor: pointer;

						&:hover {
							color: $table-body-text;
						}
					}
				}

				.detail {
					// margin-top: 15px;
					margin: 15px 10px 0px 0px;
				}

				.edit-desc-detail {
					.detail-input {
						width: 100%;
						padding: 5px 10px;
						border: 1px solid #11171E;
						border-radius: 5px;
						color: #324054;
						height: 53px;
						resize: none;

						&:focus {
							outline: none;
						}
					}

					.detail-input::-webkit-scrollbar {
						width: 0 !important
					}
					
					.detail-footer {
						font-size: 16px;
						margin-top: 5px;
						float: right;

						.cancel-btn {
							color: #ACACAC;
							border: 1px;
							font-size: 14px;
							background-color: $white;
							// border: none;
							font-weight: 600;
							margin-right: 10px;
							width: 60px;

							&:focus,
							&:active {
								outline: none;
							}
						}

						.save-description-btn {
							font-size: 14px;
							padding: 7px;
							// border-radius: 8px;
							// background-color: #00CEFF;
							// color: $white;
							width: 120px;
						}
					}
				}

				.dataset-date-container {
					width: 35%;
				}

				.dataset-date {
					display: flex;
					padding-bottom: 3px;
					font-size: 12px;
					align-items: center;
					.date-heading {
						color: #bbbbbb;
						width: 90px;
					}
				}

				.detail-date {
					padding-left: 10px;
					font-size: 14px;
				}
			}

			.dataset-studies {
				background-color: #f8f9fb;
			}

			.dataset-sign-info {
				padding-top: 15px;
				font-size: 12px;

				.desc-date {
					font-weight: bold;
					color: #9c9c9c;
					padding-right: 10px;
				}
			}
		}

		.right-panel {
			width: 20%;
			text-align: center;

			.btn-process {
				// background-color: #17df6d;
				// color: rgba(14, 97, 50, 0.55);
				// border-color: #17df6d;
				width: 85%;
			}

			.btn-reject {
				padding-top: 10px;
				display: block;
				text-decoration: underline;
				color: #bbbbbb;
				cursor: pointer;
				font-family: 'roboto-bold';
				font-size: 12px;
			}

			.disable-acquire {
				background-color: #bbbbbb;
				border-color: #bbbbbb;
				color: #324054;
			}
		}
	}

	.dataset-studies {
		margin-top: 20px;

		.heading {
			color: #bbbbbb;
			line-height: 30px;
			font-size: 12px;

		}

		.processing-study {
			margin-bottom: 10px;
			padding: 10px;
			background-color: #f8f9fb;

			.processing-study-row {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				color: #9C9C9C;
				margin-bottom: 5px;

				.study-date {
					font-size: 15px;
					display: flex;
					align-items: center;
					margin-right: 22px;
					width: 100px;

					.err {
						height: 30px;
						width: 30px;
						position: relative;
						border-radius: 5px;
						background-color: #ff5f5f;
						margin-right: 10px;
						display: inline-block;
						text-align: center;
						color: #fff;
						font-weight: bold;
						font-size: 20px;
						line-height: 30px;
					}
				}

				.study-name {
					font-size: 16px;
					min-width: 100px;
					width: 57%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					color: #324054;
				}

				.processedby {
					width: 25%;
					font-size: 12px;

					.processedby-username {
						font-size: 14px;
					}
				}

				.records {
					i {
						font-size: 12px;
					}
				}

				.study-error {
					color: #ff5f5f;
				}

				.study-status-1 {
					background-color: $new-Button-blue;
					color: $blue-button-text-color;
					padding: 6px 10px;
					border-radius: 5px;
					font-size: 11px;
					min-width: 95px;
					text-align: center;
				}

				.study-status-2 {
					border: 1px solid $new-Button-blue;
					color: $new-Button-blue;
					padding: 6px 10px;
					border-radius: 5px;
					font-size: 11px;
					font-style: italic;
					min-width: 95px;
					text-align: center;
					margin-left: 35px;
				}

				.study-status-3 {
					background-color: #ff5f5f;
					color: #fff;
					padding: 6px 10px;
					border-radius: 5px;
					font-size: 11px;
					font-style: italic;
					min-width: 95px;
					text-align: center;
				}
			}

			.progress {
				height: 8px;
			}

			.success-progress-bar .progress-bar {
				border-radius: 5px;
				background-color: #17df6d;
			}

			.progress-bar-striped {
				background-image: linear-gradient(45deg,
						rgba(255, 255, 255, 0.15) 25%,
						transparent 25%,
						transparent 50%,
						rgba(10, 0, 0, .15) 50%,
						rgba(0, 0, 0, .15) 75%,
						#00150400 75%,
						#00150400);
			}
		}

		.processed-study-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #f8f9fb;
			padding: 10px;
			color: #9C9C9C;
			margin-bottom: 8px;

			.study-date {
				font-size: 15px;
				display: flex;
				align-items: center;
				width: 100px;

				.err {
					height: 30px;
					width: 30px;
					position: relative;
					border-radius: 50px;
					background-color: #ff5f5f;
					margin-right: 10px;
					display: inline-block;
					text-align: center;
					color: #fff;
					font-weight: bold;
					font-size: 20px;
					line-height: 30px;
				}
			}

			.study-name {
				font-size: 16px;
				min-width: 100px;
				width: 20%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: #324054;
			}

			.records {
				width: 15%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				i {
					font-size: 12px;
				}
			}

			.time {
				width: 15%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				i {
					font-size: 12px;
				}
			}

			.processedby {
				width: 25%;

				i {
					font-size: 12px;
				}
			}

			.study-error {
				color: #ff5f5f;
			}

			.study-status-1 {
				background-color: $new-Button-blue;
				color: $blue-button-text-color;
				padding: 6px 10px;
				border-radius: 5px;
				font-size: 11px;
				min-width: 95px;
				text-align: center;
			}

			.study-status-2 {
				border: 1px solid $new-Button-blue;
				color: $new-Button-blue;
				padding: 5px 10px;
				border-radius: 5px;
				font-size: 11px;
				font-style: italic;
				min-width: 95px;
				text-align: center;
			}

			.study-status-3 {
				background-color: #ff5f5f;
				color: #fff;
				padding: 6px 10px;
				border-radius: 5px;
				font-size: 11px;
				font-style: italic;
				min-width: 95px;
				text-align: center;
			}

			.dark {
				color: #324054;
				font-family: 'roboto-bold';
			}
		}

		.study-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #f8f9fb;
			padding: 10px;
			color: #bbbbbb;
			margin-bottom: 10px;

			&.error {
				justify-content: flex-start;

				.study-date {
					margin-right: 22px;
				}
			}

			.study-date {
				font-size: 15px;
				display: flex;
				align-items: center;
				width: 100px;

				.err {
					height: 24px;
					width: 24px;
					position: relative;
					border-radius: 50px;
					background-color: #ff5f5f;
					margin-right: 10px;
					display: inline-block;
					text-align: center;
					color: #fff;
					font-weight: bold;
					font-size: 16px;
					line-height: 24px;
				}
			}

			.study-name {
				font-size: 16px;
				min-width: 100px;
				width: 20%;
				white-space: nowrap;
				overflow: hidden;
				color: #324054;
				text-overflow: ellipsis;
			}

			.records {
				i {
					font-size: 12px;
				}
			}

			.study-error {
				color: #ff5f5f;
				width: 65%;
				margin-left: 40px;
			}

			.study-status-1 {
				background-color: #17df6d;
				color: #129a4c;
				padding: 6px 10px;
				border-radius: 5px;
				font-size: 11px;
				min-width: 95px;
				text-align: center;
			}

			.study-status-2 {
				border: 1px solid #17df6d;
				color: #17df6d;
				padding: 6px 10px;
				border-radius: 5px;
				font-size: 11px;
				font-style: italic;
				min-width: 95px;
				text-align: center;
			}

			.study-status-3 {
				background-color: #ff5f5f;
				color: #fff;
				padding: 6px 10px;
				border-radius: 5px;
				font-size: 11px;
				font-style: italic;
				min-width: 95px;
				text-align: center;
			}

			.dark {
				color: #324054;
				font-family: 'roboto-bold';
			}
		}
	}

	.dataset-detail-info {
		display: flex;
		padding-top: 40px;

		.heading {
			font-size: 20px;
			font-family: 'roboto-medium';
			line-height: 20px;
			color: #324054;
		}

		.dataset-activity {
			width: 25%;
		}

		.dataset-profile-gender {
			width: 75%;

			.profile-info-container {
				display: flex;	
				// justify-content: space-between;
				padding-top: 40px;

				.box {
					width: 45%;
					margin-right: 20px;
					border-radius: 5px;
				}

				.info-row {
					display: flex;

					.text {
						width: 50%;
						text-align: right;
						padding: 5px;
						color: #949494;
						font-family: 'roboto-medium';
						font-size: 16px;
						white-space: nowrap;
					}

					.value {
						width: 50%;
						text-align: left;
						padding: 5px;
						font-family: 'roboto-medium';
						font-size: 16px;
						color: #324054;
					}
				}
			}

			.gender-container {
				margin-top: 40px;
				margin-right: 50px;
				border-radius: 5px;

				.chart {
					height: 200px;
				}
			}

			.age-container {
				margin-top: 40px;
				border-radius: 5px;
				margin-right: 50px;

				.chart {
					padding-top: 20px;
				}
			}
		}

		.box {
			background-color: #f8f9fb;
			padding: 20px;
		}

		.no-dataset-profile {
			border: 1px solid #949494;
			padding: 40px;
			margin-top: 40px;
			height: 60%;
			color: #949494;
			text-align: center;
		}
	}
}

.reject-modal {
	width: 636px !important;

	.modal-content {
		.modal-header {
			border: none;
			height: 80px;

			.close {
				font-size: 30px;
				font-weight: 100;
				padding: 20px;
				color: #979797;

				&:focus {
					border: none;
				}
			}
		}

		.modal-body {
			border: none;
			color: $header-text;
			font-family: roboto-light;
			font-size: 25px;
			font-weight: 300;
			letter-spacing: 0;
			text-align: center;
			padding: 60px 60px 30px;
		}
	}

	.modal-footer {
		display: flex;
		flex-direction: column;
		border: none;
		align-items: center;
		justify-content: center;
		padding: 20px 60px;
		padding-bottom: 80px;
		height: 200px;

		.buttons {
			box-sizing: border-box;
			font-size: 14px;
			height: 50px;
			width: 300px;
			border-radius: 5px;
		}

		.cancel {
			margin-top: 20px;
			cursor: pointer;
			color: $modal-label-color;
			font-family: roboto-regular;
			font-size: 14px;
			letter-spacing: 0;
			line-height: 19px;
			text-align: center;
		}
	}
}

.sign-icon {
	margin: 0px 0px 6px 8px;
	cursor: pointer;
}

@keyframes spin {
	from {
		transform: rotate(360deg);
	}

	to {
		transform: rotate(0deg);
	}
}