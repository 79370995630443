@import '../../variables.scss';

#auditreport-container{
	padding: 20px 3% 0;

.auditreport {
	min-height: 550px;
	min-width: 800px;
	padding: 30px 40px; //75px 20px 20px 75px;
	width: 100%;
	background-color: $white;
	border-radius: 5px;
	box-shadow: 0 0 14px 0 rgba(31, 53, 77, 0.2);

	.generate-report {
		// display: flex;
		// flex-direction: row;
		// flex-wrap: wrap;
		border: 1px solid #D0D0D0;
		border-radius: 5px;
		background-color: #FFFFFF;
		box-shadow: 2px 7px 10px 0 rgba(0,0,0,0.23);
		width: 100%;
		padding: 20px;
		margin: 10px 0px;

		.sub-title {
			font-size: 20px; //30px;
			font-weight: bold;
			width: 30%;
			line-height: 39px;
			font-family: "roboto-light";
			color: $color-grey;
			// padding: 20px 30px;
			// padding-left: 0px;
			margin-bottom: 20px;
		}

		.calendar-container {
			display: flex;
			width: 100%;
			align-items: flex-end;
			padding: 0px;
			margin: 0px;

			.start {
				width: 40%;
				display: flex;
				flex-direction: column;
				// padding-right: 40px;
				padding-right: 3%;
				position: relative;

				.txt {
					font-size: 12px;
					font-family: roboto-medium;
					line-height: 20px;
					color: $modal-label-color;
					padding: 5px 0px;
				}

			}

			.end {
				width: 40%;
				display: flex;
				flex-direction: column;
				// padding-right: 40px;
				padding-right: 3%;
				position: relative;

				.txt {
					font-size: 12px;
					font-family: roboto-medium;
					line-height: 20px;
					color: $modal-label-color;
					padding: 5px 0px;
				}
			}

			input {
				box-sizing: border-box;
				border: 1px solid rgba(156, 156, 156, .5);
				border-radius: 3px;
				background-color: $white;
				box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
				height: 40px;
				// width: 250px;
				width: 100%;
				padding-left: 35px;
			}

			.icon-calendar {
				color: $save-btn-color;
				font-size: 20px;
				position: absolute;
				bottom: 5px;
				left: 8px;
				z-index: 10;
				.calendar-icon {
					height: 20px;
					width: 20px;
				}
			}

			.react-datepicker__header {
				background-color: $white;
			}

			.react-datepicker__day {
				margin: 0.36rem;
			}

			.react-datepicker__day--selected {
				background-color: $save-btn-color;
				border-radius: 50%;
			}

			.report_btn {
				width: 20%;
			}
		}
	}

	.list-report {
		.list-header {
			position: relative;
			padding-bottom: 10px;

			.list-title {
				color: $header-text;
				font-family: roboto-medium;
				font-size: 20px;
			}

			.refresh-list {
				cursor: pointer;
				position: absolute;
				right: 5px;
				top: 10px;
				text-decoration: underline;
				color: $table-body-text;
				font-family: roboto-bold;
				font-style: italic;
				line-height: 17px;

				.refresh-logo {
					color: $new-Button-blue;
					margin-right: 5px;
					font-size: 20px;
				}
			}
		}

		.tbl-list {
			margin-top: 10px;
			width: 100%;

			.tbl-header {
				// background-color: $grid-header;
				color: $tbl-header-txt;
				font-family: roboto-medium;
				font-size: 12px;
				// color: $modal-label-color;
				color: $inactive;

				td {
					// text-align: left;
					// height: 40px;
					// padding: 0 10px;
					// width: 20%;

					&>div {
						text-align: left;
						display: inline-block;
						border-radius: 5px;
						margin: 0px 10px 0px 0px;
						width: 90%;
						padding: 8px;
						background-color: #f8f9fb;
					}
					
				}
				#download-link{
					width: 80px;
				}
			}

			.tbl-body {
				.tbl-row {
					padding: 10px;
					color: $table-body-text;
					border-bottom: 1px solid rgba(187, 187, 187, .5);
					height: 40px;

					td {
						text-align: left;
						padding-left: 10px;

						&>div {
							display: inline-block;
							color: $tbl-label-color;
							font-family: roboto-medium;
							line-height: 16px;
						}
					}

					.status {
						width: 90px;
						font-family: roboto-bold;
						font-style: italic;
						text-align: center;
						border-radius: 5px;
						padding: 3px;
					}

					.processing {
						color: $new-Button-blue;
						border: 1px solid $new-Button-blue;
					}

					.processed {
						// color: $white;
						color: $blue-button-text-color;
						background-color: $new-Button-blue;
						border: 1px solid $new-Button-blue;
					}

					.failed {
						color: $error-red;
						border: 1px solid $error-red;
					}

					.download {
						height: 17px;
						width: 109px;
						color: $save-btn-color;
						font-family: roboto-regular;
						font-style: italic;
						text-decoration: underline;
						cursor: pointer;
						text-align: right;
    					padding-right: 10px;
					}

					.disabled {
						&:hover {
							cursor: not-allowed;
						}

						color: $inactive;
					}
				}
			}
		}
	}

	.error-container {
		height: 20px;

		.error {
			color: $error-red;
			margin-left: 30%;
		}
	}
}
}