@import '../../variables.scss';

#user-container {
	background-color: $white;
	padding: 20px 3% 0;

	.userlist {
		min-height: 300px;
		min-width: 800px;
		// max-height:600px;
		// overflow:scroll;
		padding: 20px;
		width: 100%;
		background-color: $white;
		border-radius: 5px;
		box-shadow: 0 0 14px 0 rgba(31, 53, 77, 0.2);

		.no-more-records{
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $pagination-text;
		}
		.loading-list {
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			
			.loading{
				height: 24px;
				width: 65px;
				color: #324054;
				font-size: 12px;
				font-family: roboto-bold;
				// font-weight: bold;
				line-height: 17px;
			}
			.dot-pulse {
				position: relative;
				top: 15px;
				left: -10048px;
				width: 6px;
				height: 6px;
				border-radius: 3px;
				background-color: #5FD770;
				box-shadow: 9999px 0 0 -5px #5FD770;
				animation: dotPulse 1.5s infinite linear;
				animation-delay: .25s;
			  }
			  
			  .dot-pulse::before, .dot-pulse::after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 0;
				width: 6px;
				height: 6px;
				border-radius: 3px;
				background-color: #5FD770;
			  }
			  
			  .dot-pulse::before {
				box-shadow: 9989px 0 0 -5px #5FD770;
				animation: dotPulseBefore 1.5s infinite linear;
				animation-delay: 0s;
			  }
			  
			  .dot-pulse::after {
				box-shadow: 10010px 0 0 -5px #5FD770;
				animation: dotPulseAfter 1.5s infinite linear;
				animation-delay: .5s;
			  }
			  
			  @keyframes dotPulseBefore {
				0% {
				  box-shadow: 9989px 0 0 -5px #5FD770;
				}
				30% {
				  box-shadow: 9989px 0 0 2px #5FD770;
				}
				60%,
				100% {
				  box-shadow: 9989px 0 0 -5px #5FD770;
				}
			  }
			  
			  @keyframes dotPulse {
				0% {
				  box-shadow: 9999px 0 0 -5px #5FD770;
				}
				30% {
				  box-shadow: 9999px 0 0 2px #5FD770;
				}
				60%,
				100% {
				  box-shadow: 9999px 0 0 -5px #5FD770;
				}
			  }
			  
			  @keyframes dotPulseAfter {
				0% {
				  box-shadow: 10010px 0 0 -5px #5FD770;
				}
				30% {
				  box-shadow: 10010px 0 0 2px #5FD770;
				}
				60%,
				100% {
				  box-shadow: 10010px 0 0 -5px #5FD770;
				}
			  }
		}
		.tbl-list {
			width: 100%;

			.tbl-header {
				// background-color: $grid-header;
				// color: $tbl-header-txt;
				font-family: roboto-regular;
				// text-transform: uppercase;
				font-size: 11px;

				td {
					// margin-right: 10px;
					// background-color: $grid-header;
					// border-radius: 5px;
					// text-align: left;
					// height: 40px;
					// padding: 0 10px;

					&>div {
						text-align: center;
						display: inline-block;
						border-radius: 5px;
						margin: 0px 5px 0px 0px;
						width: 100%;
						padding: 8px 10px;
						background-color: #f8f9fb;
					}
				}
			}

			.tbl-body {
				.tbl-row {
					padding: 15px;
					color: $table-body-text;
					border-bottom: 1px solid rgba(187, 187, 187, .5);

					td {
						text-align: left;
						// font-size: 14px;
						font-weight: 500;
						padding-left: 10px;

						&>div {
							// text-align: center;
							display: inline-block;
						}
					}

					&:hover .fa-pencil-alt {
						opacity: 1;
					}
				}

				.inactive-row {
					color: $inactive;
					font-style: italic;
				}

				
			}

			.status {
				width: 60px;

				&.active {
					color: $active;
				}
			}

			.name {
				min-width: 150px;
				font-family: roboto-medium;
				word-break: break-all;
			}

			.email-text {
				overflow: hidden;

				.line-brk {
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;	
				}		
			}
			.email {
				min-width: 150px;
				word-break: break-all;
				text-decoration: none;
			}

			.email-blue {
				color: $save-btn-color;
			}

			.active_email {
				padding-right: 8px;
			}

			.inactive-email {
				color: $inactive;
				font-style: italic;
			}

			.phone {
				width: 130px;
			}

			// .organisation {
			// 	min-width: 100px;
			// }
			.role {
				max-width: 200px;
				line-height: 24px;
			}

			// .date {
				// max-width: 100px;
			// }

			.creation-date {
				padding-left: 10px;
			}

			.edit {
				width: 30px;
				height: 35px;
				cursor: pointer;
				line-height: 34px;

				.fa-pencil-alt {
					opacity: 0;
				}
			}
		}
	}
}

.email-popover {
	width: auto;
	max-width: 100%;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	.email-popover-content {
		
		.email-icon {
			padding-right: 8px;
		}

		.text {
			color: $save-btn-color;
			font-family: roboto-regular;
			letter-spacing: 0;
			line-height: 19px;
		}

		.inactive-email {
			color: $inactive;
			font-style: italic;
		}
	}
}