@import '../../variables.scss';

.footer{
  display: flex;
  height: 80px;
  background-color: $modal-footer-bg;
  align-items: center;
  justify-content: center;
  
.buttons{
    box-sizing: border-box;
    height: 40px;
    width: 170px;
    // border-radius: 29px;
    // text-transform: uppercase;
    font-size: 11px;
    font-weight: roboto-bold;
  }

  .cancelbtn{
    margin-right: 15px;
  }
}