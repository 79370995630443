@import '../../variables.scss';

.display-signature{
	width: 450px !important;
	.modal-content {
		.modal-header {
			border: none;
			.close{
				font-size: 30px;
				font-weight: 100;
				padding: 15px;
				color: #979797;
				&:focus{
					border: none;
				}
			}
		}
		.modal-body {
			border: none;
			color: $active-nav-link;
			font-family: roboto-medium;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 14px;
			text-align: center;
			padding: 25px;
			padding-top: 0px;
		}
	}
	.modal-footer {
        border: none;
	}
}