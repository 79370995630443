@import '../../variables.scss';

.btn.btn-normal {
	background-color: $new-Button-blue;//$button-blue;
	border-color:  $new-Button-blue;//$button-blue;
	color:$blue-button-text-color;//$button-text-color;
	font-family: roboto-bold;
	font-size: 12px;
	height: 40px;
	padding: 0 25px;
	border-radius: 5px;

	&:active:hover {
		box-shadow: 0 0px 5px rgba(46, 63, 85, .5);
		background-color: $button-blue;
		// color: $button-text-color;
		color: $white;
		border-color: $button-blue;
	}
}

.plus-icon {
	// font-size: 15px;
	// margin-right: 1px;
	-webkit-text-stroke: 0.8px $new-Button-blue;
}

.btn.save-button {
	background-color: $new-Button-blue;//$button-blue;
	border-color:  $new-Button-blue;//$button-blue;
	color: $blue-button-text-color; //$button-text-color;
	border: 1px solid $new-Button-blue;
	font-family: roboto-bold;
	border-radius: 5px;

	&:hover,
	&:active:hover {
		// box-shadow: 0 0px 5px rgba(46, 63, 85, .5);
		background-color: $new-Button-blue;//$button-blue;
		border-color:  $new-Button-blue;//$button-blue;
		color: $blue-button-text-color; //$button-text-color;
		border: 1px solid $new-Button-blue;
		background-color: $new-Button-blue;
	}
}

.btn.cancel-button {
	// color: $cancel-btn-text;
	// background-color: $cancel-btn-color;
	// border-color: $cancel-btn-color;
	color: #9C9C9C;
	text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
	width: 65px !important;

	&:hover,
	&:active:hover {
		// color: $cancel-btn-text;
		// background-color: $cancel-btn-color;
		// border-color: $cancel-btn-color;
		color: #9C9C9C;
		text-decoration: underline;
		background-color: transparent;
		outline: none;
		border-color: transparent;
	}
}

.btn.delete-button {
	color: $white;
	border-color: $error-red;
	background-color: $error-red;
	font-family: roboto-bold;
	border-radius: 5px;

	&:hover,
	&:active:focus {
		box-shadow: 0 0px 5px rgba(46, 63, 85, .5);
		color: $white;
		border-color: $error-red;
		background-color: $error-red;
	}
}