@import '../../variables.scss';

.study-modal {
	max-width: none !important;
	width: 600px !important;

	.modal-content {
		.modal-header{
			padding: 25px;
			border: none;
			.close{
				font-size: 30px;
				font-weight: 100;
			}
		}
		.study-title {
			font-size: 26px;
			font-family: roboto-light;
			text-align: center;
			width: 100%;
			padding: 5px;
		}

		.modal-body {
			padding: 0px 60px;
			height: 240px;
			border: none;
			
			.study-message {
				color: #324054;
				font-family: roboto-regular;
				font-size: 16px;
				letter-spacing: 0;
				line-height: 21px;
				text-align: center;
				margin-bottom: 2em;
			}

			.study-select {
				width: 100%;
				height: 32px;
				display: inline-block;
			}

			textarea {
				margin-top: 20px;
				box-sizing: border-box;
				font-style: italic;
				border: 1px solid rgba(156, 156, 156, .5);
				border-radius: 5px;
				background-color: $white;
				box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
				padding: 10px;
				width: 100%;
				height: 105px;
			}

			textarea:focus {
				outline: none;
			}

			.error{
				span {
					color: $error-red;
				}
			}
		}

		.modal-footer {
			padding: 30px;
			display: flex;
			border: none;
			flex-direction: column;

			.buttons {
				width: 50%;
				height: 50px;
			}

			.cancel {
				cursor: pointer;
				color: $modal-label-color;
				font-family: roboto-regular;
				font-size: 14px;
				letter-spacing: 0;
				line-height: 19px;
				text-align: center;
				padding: 20px;
			}
		}
	}
}