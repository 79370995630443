@import '../../variables.scss';

.research-network-container {
	padding: 20px 3% 0;
	
	.no-more-records{
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $pagination-text;
		padding-bottom: 30px;
	}

	.unauthorised-user-forRP{
		padding: 50px;
		text-align: center;
	}
	.loading-list {
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 30px;
		
		.loading{
			height: 24px;
			width: 65px;
			color: #324054;
			font-size: 12px;
			font-family: roboto-bold;
			// font-weight: bold;
			line-height: 17px;
		}
		.dot-pulse {
			position: relative;
			top: 15px;
			left: -10048px;
			width: 6px;
			height: 6px;
			border-radius: 3px;
			background-color: #5FD770;
			box-shadow: 9999px 0 0 -5px #5FD770;
			animation: dotPulse 1.5s infinite linear;
			animation-delay: .25s;
		  }
		  
		  .dot-pulse::before, .dot-pulse::after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			width: 6px;
			height: 6px;
			border-radius: 3px;
			background-color: #5FD770;
		  }
		  
		  .dot-pulse::before {
			box-shadow: 9989px 0 0 -5px #5FD770;
			animation: dotPulseBefore 1.5s infinite linear;
			animation-delay: 0s;
		  }
		  
		  .dot-pulse::after {
			box-shadow: 10010px 0 0 -5px #5FD770;
			animation: dotPulseAfter 1.5s infinite linear;
			animation-delay: .5s;
		  }
		  
		  @keyframes dotPulseBefore {
			0% {
			  box-shadow: 9989px 0 0 -5px #5FD770;
			}
			30% {
			  box-shadow: 9989px 0 0 2px #5FD770;
			}
			60%,
			100% {
			  box-shadow: 9989px 0 0 -5px #5FD770;
			}
		  }
		  
		  @keyframes dotPulse {
			0% {
			  box-shadow: 9999px 0 0 -5px #5FD770;
			}
			30% {
			  box-shadow: 9999px 0 0 2px #5FD770;
			}
			60%,
			100% {
			  box-shadow: 9999px 0 0 -5px #5FD770;
			}
		  }
		  
		  @keyframes dotPulseAfter {
			0% {
			  box-shadow: 10010px 0 0 -5px #5FD770;
			}
			30% {
			  box-shadow: 10010px 0 0 2px #5FD770;
			}
			60%,
			100% {
			  box-shadow: 10010px 0 0 -5px #5FD770;
			}
		  }
	}
	.header-container {
		display: flex;
		.status-tabs {
			display: flex;
			margin-bottom: 60px;
			width: 50%;
			.dataset-status {
				color: #bbbbbb;
				font-family: 'roboto-bold';
				padding: 10px 15px;
				font-size: 14px;
				cursor: pointer;

				&.active {
					color: #2e3f55;
					border-bottom: 2px solid #00ceff;
				}
			}
		}
		.legends {
			width: 50%;
			display: flex;
			.legend-container {
				padding: 0px 10px;
				width: 200px;
				/* text-align: center; */
				display: flex;
				justify-content: flex-start;
				flex-direction: column;
				align-items: center;
				font-family: 'roboto-bold';
				.counts {
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					/* padding: 5px; */
					min-width: 25px;
					min-height: 25px;
					&.type_1 {
						border: 1px solid #dfdfdf;
						background-color: #dfdfdf;
						color: #324054;
					}
					&.type_2 {
						border:1px solid $navy-blue;
						color: $navy-blue;
						background-color: $white;
					}
					&.type_3 {
						border: 1px solid $navy-blue;
						background-color: $navy-blue;
						color: $white;
					}
					&.type_4 {
						border: 1px solid $new-Button-blue;
						color: $new-Button-blue;
						background-color: $white;
					}
					&.type_5 {
						border: 1px solid $new-Button-blue;
						color: $blue-button-text-color;
						background-color: $new-Button-blue;
					}
					&.type_6 {
						border: 1px solid transparent;
						color: #fff;
						background-color: #324054;
					}
				}
				.status-text {
					color: #2e3f55;
					font-size: 14px;
					padding-top: 10px;
					&.type-2 {
						font-family: 'roboto-regular';
						font-style: italic;
					}
					&.type-4 {
						font-family: 'roboto-regular';
						font-style: italic;
					}
				}
			}
		}
	}
	.paginationComponent{
		padding:5px;
		height:auto;
		margin-bottom: 40px;
	}
}
