@import '../../variables.scss';

#main {
	height: 100vh;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $light-grey;
	background: $login-bgcolor;

	.login-container {
		background-color: $white;
		width: 50%;
		max-width: 650px;
		min-width: 500px;
		padding: 6% 10%;
		border-radius: 5px;

		.login {
			height: auto;

			.login_header {
				color: $login-text;
				text-align: center;
				line-height: 1.5em;
				margin-bottom: 0.8em;
				margin-top: 0.2em;

				.logo_img {
					width: 250px;
					margin-bottom: 50px;
					margin-left: -30px;
				}

				.text {
					display: block;
					font-family: roboto-light;
					font-size: 30px;
				}
			}
			.form-control:focus {
				box-shadow: none;
			}

			.form-field-control {
				position: relative;
				margin-bottom: 1rem;

			.form-field-label {
				font-size: 12px;
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				padding: 15px 12px 0px 12px;
				transition: all 0.2s;
				margin-bottom: 0;
				text-align: left;
				transform: translateY(-11px);
			}

			.form-field-input {
				background-color: $input-bgcolor;
				padding: 20px 14px 5px;
				border-radius: 5px;

				&:placeholder-shown +.form-field-label {
					font-size: 14px;
					transform: translateY(0);
				}

				&:focus +.form-field-label {
					font-size: 12px;
					transform: translateY(-11px);
				}
			}
		}

			input[type='text'],
			input[type='password'] {
				color: $light-grey;
				background-color: $input-bgcolor;
				border: 1px solid transparent;
			}

			input[type='text']:focus,
			input[type='password']:focus, input:not([value=""]) {
				background-color: $white !important;
				border-color: $input-border;
				border: 1px solid $header-text;
				border-radius: 5px;
			}

			.error_field {
				text-align: left;
				height: 20px;

				span {
					color: $error-red;
				}
			}

			.submit {
				margin-bottom: 3em;
			}

			.button {
				border-width: 1px;
				margin-top: 20px;
				padding: 1em;
				width: 100%;
				font-family: roboto-bold;
				
			}

			.forgot {
				font-weight: 500;
				color: $login-text;
				line-height: 1.5em;
				margin-bottom: 2em;
				margin-top: 0.2em;
				margin-left: '10px';
				text-decoration: underline;
				text-align: center;
				cursor: pointer;
			}
		}
	}
	.mfa-container {
        background-color: $white;
        width: 500px;
        padding: 6% 10%;
        border-radius: 10px;

        .mfa {
            height: auto;

            .mfa_header {
                color: $forget-text;
                text-align: center;
                line-height: 1.5em;
                margin-bottom: 0.7em;
                margin-top: 0.2em;

                .logo_img {
                    width: 250px;
                    margin-bottom: 50px;
					margin-left: -30px;
                }

                .text {
                    display: block;
                    font-family: roboto-light;
                    font-size: 30px;
                }
            }

            .form-control:focus {
                box-shadow: none;
            }

            .mfa-info {
                height: auto;
                width: 352px;
                color: rgba(0, 0, 0, 0.5);
                font-size: 14px;
                letter-spacing: 0;
                font-family: roboto-regular;
                margin: auto;
            }

            .digit-group {
                display: flex;
                flex-direction: row;
                justify-content: center;
                input {
                    height: 65px;
                    width: 40px;
                    color: #324054;
                    border-radius: 5px;
                    line-height: 50px;
                    text-align: center;
                    border: 1px solid #324054;
                    font-size: 35px;
                    margin: 0 10px;
                    padding: 9px;
                }
            }

            input[type='text']:focus {
                background-color: $white;
                border-color: $input-border;
                outline: none;
                border: 1px solid $header-text;
                border-radius: 4px;
            }

            .resend-otp {
                height: 19px;
                width: 83px;
                color: #324054;
                font-family: roboto-bold;
                font-size: 12px;
                text-decoration: underline;
                cursor: pointer;
                margin: 20px auto;
            }

            .button {
                border-width: 1px;
                margin-top: 20px;
                padding: 1em;
                border-radius: 50px;
                width: 100%;
                font-family: roboto-bold;
                margin: 40px auto;
            }
        }
	}
	.error_field {
		span {
			color: $error-red;
		}
	}
}