@import '../../variables.scss';

.research-network-detail-container {
		padding: 20px 3% 0;

	.header-container {
        height: 70px;
        .goback{
            font-size:16px;
            font-family: 'roboto-medium';
            cursor: pointer;
        }
    }
    .unauthorised-user-forRP{
        padding: 50px;
		text-align: center;
    }
    .dataset-container {
        &.active {
            cursor: default;
        }
    }
}
