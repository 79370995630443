@import '../../variables.scss';

.header {
	margin-bottom: 25px;
	display: flex;

	.left {
		width: 80%;
		display: flex;
		text-align: left;
		.page-pretext{
			display: inline-block;
			color: #A0A0A0;
			font-family: roboto-regular;
			font-size: 26px;
			padding-right: 10px;
		}
		.page-title {
			display: inline-block;
			color: $header-text;
			font-family: roboto-bold;
			font-size: 26px;
			padding-right: 20px;
			min-width: fit-content;

		}

		.search-container {
			// width: 65%;
			display: inline-block;
			position: relative;
			width: -webkit-fill-available;

			img {
				color: $inactive;
				font-size: 20px;
				height: 15px;
				width: 15px;
				/* margin-right: -25px; */
				position: absolute;
				left: 10px;
				top: 11px;
			}

			.search {
				width: 95%;
				// color: $inactive;
				font-size: 12px;
				font-weight: bold;
				// border: none;
				// border-bottom: 0.5px solid $inactive;
				border: 0.5px solid $inactive;
				border-radius: 5px;
				background-color: $off-white;
				padding: 7px 20px 7px 35px;
				font-style: italic;
				// padding-left: 25px;
				// padding-right: 20px;
				height:38px;

				&::placeholder {
					color: $inactive;
					font-size: 14px;
				}

				&:focus {
					outline: none;
				}
			}

			.searchCancel {
				position: absolute;
				display: inline-block;
				/* height: 10px; */
				font-size: 15px;
				color: $inactive;
				background-color: transparent;
				right: 0px;
				top: 7px;
				width: 30px;
				text-align: center;
				cursor: pointer;
			}
		}
	}

	.right {
		width: 20%;
		text-align: right;

		div {
			display: none;
		}

		.logout {
			// text-transform: uppercase;
			font-size: 12px;
			height: 38.56px;
			width: 75px;
			border-radius: 23.13px;
			background-color: $success-green;
			border-color: $success-green;
			font-family: roboto-bold;
		}

		.header-btn {
			// text-transform: uppercase;
			font-size: 12px;
			height: 38px;
			min-width: 180px;
			padding: 0 25px;
			// border-radius: 23.13px;
			margin-right: 20px;
			// &:hover {
			// 	box-shadow: 0 0px 5px rgba(46, 63, 85, .5);
			// 	background-color: $success-green;
			// 	border-color: $success-green;
			// }
		}
	}
}