@import '../../variables.scss';

.main-menu {
	&.collapsed {
		width: 75px;

		.collapse-icon {
			left: 58px;
			top: 94px;
			transform: rotate(0deg);
			// .arrow-left {
				
			// 	padding-right: 11px;
			// 	padding-bottom: 5px;
			// }
		}
	}

	&.open {
		width: 320px;
	}

	width: auto;
	height: 100vh;
	transition: width .3s linear;
	overflow: hidden;
	display: flex;

	.left-col {
		width: 75px;
		display: inline-block;
		padding-top: 40px;
		background-color: $active-sidenav;

		.logo-icon {
			width: 41px;
			height: 41px;
			margin: 0 20px 40px;
			cursor: pointer;
		}

		.file-icon {
			width: 41px;
			height: 41px;
			color: white;
			margin: 0px 20px 20px;
			cursor: pointer;
			text-align: center;
		}
		.ver-logo {
			height: 35px;
			width: 35px;
		}
		.sys-admin {
			height: 30px;
			width:30px;
		}
		.data-admin {
			height: 24px;
			width:24px;
		}
		.kn-base {
			height: 24px;
			width:24px;
		}
		

	}

	.collapse-icon {
		height: 30px;
		width: 30px;
		border-radius: 50%;
		position: fixed;
		z-index: 99;
		left: 302px;
		top: 94px;
		background-color: $sidenav-icon-bg;
		transition: all .3s linear;
		cursor: pointer;
		transform: rotate(-180deg);

		&:hover {
			box-shadow: 0 0px 5px $black;
		}

		.arrow-left {
			color: $white;
			font-size: 24px;
			line-height: 20px;
			/* font-weight: bold; */
			position: absolute;
			padding-left: 8px;
			padding-top: 5px;
		}
	}

	.logo-container {
		height: 125px;
		display: flex;
		align-items: flex-start;
		padding: 40px 0 40px 20px;

		.logo_icon {
			width: 41px;
			height: 41px;
			margin-right: 20px;
		}

		.logo-with-text {
			display: inline-flex;
			flex-direction: column;
			position: relative;

			.logo_img {
				width: 200px;
			}

			.logo-text {
				color: $logo-sidenav;
				font-size: 12px;
				text-align: center;
				line-height: 30px;
				font-weight: bold;
				font-family: roboto-bold;
				letter-spacing: 1px;
				display: inline-block;
				padding-left: 35px;
				// position: absolute;
				// top: 25px;
				width: 100%;
				white-space: nowrap;
			}
		}
	}

	.right-col {
		width: 240px;
		overflow: hidden;

		.logo-icon {
			width: 41px;
			height: 41px;
			margin-right: 20px;
		}
		.home-icon {
			height: 20px;
			width: 20px;
			margin: 6px 30px 10px;
		}

		ul {
			margin: 0;
			padding: 0;

			&.nav-links-dadmin-scroll {
				height: 68%; //set to 70% to add virtual appliance link
				overflow-y: scroll;
				line-height: 1;
				scrollbar-width: none;
			}

			&.nav-links-dadmin {
				height: 68%; //set to 70% to add virtual appliance link
				overflow-y: scroll;
				line-height: 1;
				mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
				scrollbar-width: none;
			}

			// &.nav-links-dadmin-height {
			// 	height: 43%;
			// 	overflow-y: scroll;
			// 	line-height: 1;
			// 	// mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
			// }

			&.nav-links-dadmin::-webkit-scrollbar,
			&.nav-links-dadmin-scroll::-webkit-scrollbar {
				width: 0px;
				background: transparent;
				/* Make scrollbar invisible */

				//property to make scrollbar invisible in firefox
				scrollbar-width: none;
			}

			.list {
				position: relative;
				display: inline-block;
				line-height: 70px;
				color: $white;
				width: 240px;
				overflow: hidden;

				&:hover {
					background-color: $active-link;
				}

				&.active {
					background-color: $active-link;

					.fa {
						background-color: transparent;
					}

					a {
						font-family: roboto-bold;
					}
				}

				.menu-icon {
					margin-left: 28px;
					margin-right: 28px;
					height: 28px;
					width: 28px;
				}

				a {
					display: flex;
					color: $white;
					font-size: 16px;
					text-decoration: none;
					font-family: roboto-medium;

					.fa-2x {
						font-size: 2em;
					}

					.fa {
						display: table-cell;
						width: 78px;
						height: 40px;
						text-align: center;
						vertical-align: middle;
						font-size: 33px;
						line-height: 17px;
						padding-left: 7px;
					}

					.nav-text {
						position: relative;
						display: table-cell;
						padding-left: 15px;
					}
				}
			}

			.document-section {
				height: 150px;
				overflow: scroll;
			}

			// .profileRP {
			// 	// position: absolute !important;
			// 	// left: 0;
			// 	// top: -800%;
			// 	// width: 320px;

			// 	.document-list{
			// 		// height: 400px !important;
			// 	}

			// 	.profile-content {
			// 		display: flex;
			// 		flex-direction: column;

			// 		li {
			// 			a {
			// 				color: white;

			// 				.fa {
			// 					color: white;
			// 				}
			// 			}
			// 		}
			// 	}
			// }

			&.profile {
				height: 20%;
				margin: 0;
				padding: 0;
				position: absolute;
				left: 0;
				bottom: 10px;
				width: auto;
				// background: linear-gradient(to bottom, rgba(1, 58, 94, 0.7) 20%, rgba(4, 48, 76, 1) 80%);

				.profile-content-onCollapsed {
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					position: absolute;
					bottom: 0px;
					width: 75px;

					.virtual-app {
						width: 75px;
						overflow: hidden;

						// white-space: nowrap;
						.nav-text {
							width: 80px;
							padding: 0px 0px 0px 80px;
							overflow: hidden;
							white-space: nowrap;
							// display: none
						}

						&.active,
						&:hover {
							padding: 5px 0px;
							background-color: $active-link;
						}
					}

					.notification {

						&.active,
						&:hover {
							background-color: $active-link;
						}

						// width: 80px;
						// overflow: hidden;
						// white-space: nowrap;
						border: none;

						.nav-text {
							width: 240px;
							overflow: hidden;
							white-space: nowrap;
							line-height: 30px;
							display: none
						}
					}

					li {
						width: 75px;
						overflow: hidden;
						white-space: nowrap;

						&:active {
							background-color: $active-link;
						}

						&:hover {
							background-color: $active-link;
						}

						a {
							&:active {
								background-color: $active-link;
							}

							&:hover {
								background-color: $active-link;
							}

							width: 75px;
							overflow: hidden;
							white-space: nowrap;
							// .fa{
							// 	padding: 5px 0px;
							// 	margin: 0px;
							// }
							// .logout{
							// 	padding: 10px 0px;
							// }
							// .nav-text {
							// 	max-width: 0px;
							// 	overflow: hidden;
							// 	// display: table-cell;
							// 	padding: 0px;
							// 	// padding-left: 15px;
							// }
						}
					}
				}

				.profile-content {
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					position: absolute;
					bottom: 0px;

					li {
						&:hover {
							background-color: $active-link;
						}

						&:active {
							background-color: $active-link;
						}

						a {
							color: $white;
							display: flex;
							font-size: 14px;
							text-decoration: none;
							font-family: roboto-medium;

							.nav-text {
								width: 240px;
								position: relative;
								display: table-cell;
								padding: 0px;
								padding-left: 15px;
							}
						}
					}
				}
			}

			.profileInfo {
				position: relative;
				display: inline-block;
				line-height: 30px;
				color: $white;
				width: 320px;
				overflow: hidden;
				cursor: pointer;

				a {
					color: $white;
					// margin-top: 1.5em;
					font-size: 14px;
					text-decoration: none;
					font-family: roboto-medium;

					.fa {
						color: $white;
						display: table-cell;
						width: 75px;
						height: 33px;
						text-align: center;
						vertical-align: middle;
						font-size: 20px;
						padding: 5px 0px;
					}
					// .logout-label{
					// 	opacity: 0.7;
					// 	font-size: 12px;
					// 	padding-left: 15px;
					// 	text-decoration: underline;
					// 	color: $button-blue;
					// }

					.logout {
						opacity: 0.7;
						font-size: 12px;
						padding: 10px 0px;
						// visibility: hidden;
					}
				}
			}

			.dataset-list {
				line-height: 30px;
				white-space: nowrap;
				padding: 10px 15px;

				&:hover {
					background-color: $active-link;
				}

				a {
					display: flex;
					margin-top: 0;

					.dataset-element {
						max-width: 95%;
						display: inline-block;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.badge {
						height: 20px;
						width: 20px;
						white-space: nowrap;
						overflow: visible;
						display: inline-block;
						border-radius: 10px;
						margin-top: 4px;
						margin-left: 10px;
						background-color: #DFDFDF;
						color: #324054;
						font-family: roboto-regular;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 1.4;
					}
				}
			}

			.virtual-app {
				width: 320px;
				padding: 5px 5px 5px 80px;
				// margin-left: 80px;
				border: 0.5px solid transparent;
				white-space: nowrap;
				line-height: 30px;
				overflow: hidden;
				color: $white;

				&.active {
					background-color: $active-link;
					border-top: 0.5px solid #979797;
					border-bottom: 0.5px solid #979797;

					a {
						font-family: roboto-bold;
					}
				}
			}

			.notification {
				&.active {
					background-color: $active-link;
				}

				width: 320px;
				padding: 5px 0px;
				// border: 0.5px solid transparent;
				white-space: nowrap;
				line-height: 30px;
				overflow: hidden;
				color: $white;

				.notification-icon {
					display: table-cell;
					width: 75px;
					height: 30px;
					text-align: center;
					vertical-align: middle;
					font-size: 20px;
					padding: 5px 0px;
				}
			}
		}

		// .document-list {
		// 	// margin-left: 80px;
		// 	color: $white;
		// 	cursor: pointer;
		// 	line-height: 7px;
		// 	margin-bottom: 10px;
		// 	height: 68%;
		// 	overflow-y: scroll;
		// 	overflow-x: hidden;

		// 	.document-item {
		// 		line-height: 50px;
		// 		white-space: nowrap;
		// 		padding: 5px 20px;
		// 		font-size: 16px;
		// 		// height: 31px;

		// 		&:hover {
		// 			background-color: $active-sidenav;
		// 		}

		// 		a {
		// 			display: flex;
		// 			margin-top: 0;
		// 			color: #A3C6D4;

		// 			.dataset-element {
		// 				max-width: 95%;
		// 				display: inline-block;
		// 				overflow: hidden;
		// 				text-overflow: ellipsis;
		// 			}
		// 		}

		// 		&.active {
		// 			background-color: #01304b;
		// 		}
		// 	}

		// 	&.document-list {
		// 		overflow-y: scroll;
		// 		margin-right: 2px;
		// 		mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
		// 		scrollbar-width: none;
		// 	}

		// 	&.document-list::-webkit-scrollbar {
		// 		width: 6px;
		// 		background: transparent;
		// 		/* Make scrollbar invisible */
		// 	}

		// 	&.document-list::-webkit-scrollbar-thumb {
		// 		width: 6px;
		// 		border-radius: 3px;
		// 		background-color: #00CEFF;
		// 	}

		// 	&.document-list-scroll {
		// 		overflow-y: scroll;
		// 		mask-image: linear-gradient(to bottom, black 100%, transparent 100%);
		// 	}

		// 	&.hover {
		// 		background-color: $active-VA;
		// 	}
		// }

		.document-list {
			// margin-left: 80px;
			color: $white;
			cursor: pointer;
			line-height: 7px;
			margin-bottom: 10px;
			// display: flex;
			// flex-direction: column;
			height: 68%;
			overflow-y: scroll;
			overflow-x: hidden;

			.list {
				position: relative;
				display: inline-block;
				color: $white;
				width: 240px;
				overflow: hidden;

				&:hover {
					background-color: $active-link;
				}

				&.active {
					background-color: $active-link;

					a {
						font-family: roboto-bold;
					}
				}
			}

			.document-item {
				line-height: 40px;
				white-space: nowrap;
				font-size: 14px;
				margin-left: 1px;

				&.active {
					background-color: $active-sidenav;
				}

				.study-title {
					display: flex;
					margin-top: 0;
					padding: 5px 10px;

					.caret-icon{
						padding: 12px;
					}
					.dataset-element {
						width: 85%;
						// max-width: 85%;
						display: inline-block;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.document-subList {
					list-style-type: none;

					.sublist-element {
						&.active {
							background-color: $active-subheading;
						}

						a {
							line-height: 35px;
							white-space: nowrap;
							padding: 3px;
							font-size: 14px;
							display: flex;
							flex-direction: column;

							.dataset-element {
								padding-left: 45px;
								max-width: 90%;
								display: inline-block;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							&:hover {
								background-color: $active-subheading;
							}
						}
					}
				}
			}

			&.hover {
				background-color: $active-link;
			}

			&.document-list {
				overflow-y: scroll;
				margin-right: 2px;
				mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
				scrollbar-width: none;
			}

			&.document-list::-webkit-scrollbar {
				width: 6px;
				background: transparent;
				/* Make scrollbar invisible */
			}

			&.document-list::-webkit-scrollbar-thumb {
				width: 6px;
				border-radius: 3px;
				background-color: $save-btn-color;
			}

			&.document-list-scroll {
				overflow-y: scroll;
				mask-image: linear-gradient(to bottom, black 100%, transparent 100%);
			}
		}
	}

	// 	.profile {
	//     margin:0;
	//     padding:0;
	// 		position: absolute;
	// 		left: 0;
	//     top: 40px;
	//     li{
	//       &:hover{
	//         background-color: $drk-blue;
	//       }
	//       position: relative;
	// 			display: block;
	//       line-height: 70px;
	//       color:#00CEFF;
	//     }
	// 	}

	// 	.profile > li:hover {
	// 		background-color: $drk-blue;
	// 	}
}

// .main-menu:hover,nav.main-menu.expanded {
//   width:250px;
//   overflow:visible;
//   border-right: 0px;
// }