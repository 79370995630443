@import '../../variables.scss';

.signature-modal {
	max-width: 40%;

	.modal-header {
		border-bottom: 1px solid transparent;
		padding: 25px;
		padding-bottom: 1rem;	
		.signature-title {
			font-size: 26px;
			font-family: roboto-light;
			text-align: center;
			width: 100%;
			padding: 5px;
		}
	
		.close{
			font-size: 30px;
			font-weight: 100;
		}
	}


	.signature-container {
		margin: 0 20px 20px;

		.signature-sutitle {
			text-align: center;
			width: 100%;
			font-size: 14px;
			align-items: center;
			display: flex;

			.signature-check {
				margin-right: 10px;
            }
			
			.sign-checktick{
				margin: 0px 10px 7px 10px;
				.sign-img-checked{
					width: 25px;
					height: 23px;
				}
				.sign-img-unchecked{
					width: 25px;
					height: 23px;
				}
			}
            .check-label {
                color: #324054;
                font-family: roboto-regular;
				font-size: 14px;
            }
          
		}

		.signature-reason{
			margin-top: 20px;
			.sign-reason-label{
				font-family: roboto-medium;
				font-weight: 500;
				font-size: 14px;
			}
			.sign-select{
				border: 1px solid #324054;
				border-radius: .3rem;
				height: 42px;
			}
		}
		.canvas-container {
			border: 1px solid #324054;

			.signature-footer {
				display: flex;
				justify-content: space-between;
				border-top: 1px solid #324054;
				padding: 20px 0;
				margin: 0 25px;
			}
		}
	}

	.modal-footer {
		display: flex;
		align-items: center;
		// padding: 0px;
		border-top: none;

		.footer {
			padding: 40px;
			width: 100%;
			flex-direction: column;
			background-color: transparent;

			.buttons {
				width: 50%;
				margin-bottom: 10px;
				padding: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 11px;
			}

			.cancelbtn {
				cursor: pointer;
				color: $cancel-btn-text;
				font-size: 11px;
				margin-bottom: 10px;
				margin-right: 0px;
				.clear-otp{
					margin-right: 20px;
				}
			}

			.cancel-button {
				background-color: transparent;
				border-color: transparent;
			}
		}
	}

	.mfa-container {
		background-color: #ffffff;
		width: 100%;
		padding: 20px;
		border-radius: 10px;
		text-align: center;
		.mfa {
			height: auto;

			.mfa_header {
				color: $forget-text;
				text-align: center;
				line-height: 1.5em;
				margin-bottom: 0.7em;
				margin-top: 0.2em;

				.logo_img {
					width: 5em;
					margin-bottom: 50px;
				}

				.text {
					display: block;
					font-family: roboto-light;
					font-size: 30px;
				}
			}

			.form-control:focus {
				box-shadow: none;
			}

			.mfa-info {
				height: auto;
				width: 352px;
				color: rgba(0, 0, 0, 0.5);
				font-size: 14px;
				letter-spacing: 0;
				font-family: roboto-regular;
				margin: auto;
				padding-bottom: 10px;
			}

			.digit-group {
				display: flex;
				flex-direction: row;
				justify-content: center;

				input {
					height: 65px;
					width: 40px;
					color: #324054;
					border-radius: 5px;
					line-height: 50px;
					text-align: center;
					border: 1px solid #324054;
					font-size: 35px;
					margin: 0 10px;
					padding: 9px;
				}

				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
				input[type=number] {
					-moz-appearance:textfield;
				}
			
				input[type=number]:focus{
					outline: none;
					border: 2px solid #324054;
				}
			}

			input[type='text']:focus {
				background-color: $white;
				border-color: $input-border;
				outline: none;
				border: 1px solid $header-text;
				border-radius: 4px;
			}

			.resend-otp {
				height: 19px;
				width: 83px;
				color: #324054;
				font-family: roboto-bold;
				font-size: 12px;
				text-decoration: underline;
				cursor: pointer;
				margin: 20px auto;
			}

			.button {
				border-width: 1px;
				margin-top: 20px;
				padding: 1em;
				border-radius: 50px;
				width: 100%;
				font-family: roboto-bold;
				margin: 40px auto;
			}
		}
	}

	.error {
		height: 20px;
		color: $error-red;
		padding-left: 25px;
		text-align: center;
	}
}
