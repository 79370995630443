@font-face {
	font-family: "roboto-regular";
	src: url("./fonts/Roboto-Regular.woff") format("woff"), url("./fonts/Roboto-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "roboto-medium";
	src: url("./fonts/Roboto-Medium.woff") format("woff"), url("./fonts/Roboto-Medium.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "roboto-bold";
	src: url("./fonts/Roboto-Bold.woff") format("woff"), url("./fonts/Roboto-Bold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "roboto-light";
	src: url("./fonts/Roboto-Light.woff") format("woff"), url("./fonts/Roboto-Light.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}
