#study-container{
    .study-info-box {
        min-height: 300px;
        min-width: 800px;
        padding: 20px 0;
        width: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 0 0 14px 0 rgba(31, 53, 77, 0.2);

        .nav-tabs{
            padding-left: 30px;
            .nav-link{
                font-size: 14px;
                color:#A0A0A0;
                border:2px solid transparent;
                font-family: roboto-medium;
                min-width: 100px;
                &.active{
                    border-bottom:2px solid #00D1FF;
                    background-color: transparent;
                    color: #2F4056;
                    font-family: roboto-bold;
                }
                &:hover{
                    color: #2F4056;
                }
            }
        }
    }
}
