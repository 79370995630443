@import '../../variables.scss';

#customer-container{
padding: 20px 3% 0;
.customerlist {
	min-height: 300px;
	min-width: 800px;
	padding: 20px;
	width: 100%;
	background-color: $white;
	border-radius: 5px;
	box-shadow: 0 0 14px 0 rgba(31, 53, 77, 0.2);

	.no-more-records{
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $pagination-text;
	}

	.loading-list {
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		
		.loading{
			height: 24px;
			width: 65px;
			color: #324054;
			font-size: 12px;
			font-family: roboto-bold;
			// font-weight: bold;
			line-height: 17px;
		}
		.dot-pulse {
			position: relative;
			top: 15px;
			left: -10048px;
			width: 6px;
			height: 6px;
			border-radius: 3px;
			background-color: #5FD770;
			box-shadow: 9999px 0 0 -5px #5FD770;
			animation: dotPulse 1.5s infinite linear;
			animation-delay: .25s;
		  }
		  
		  .dot-pulse::before, .dot-pulse::after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			width: 6px;
			height: 6px;
			border-radius: 3px;
			background-color: #5FD770;
		  }
		  
		  .dot-pulse::before {
			box-shadow: 9989px 0 0 -5px #5FD770;
			animation: dotPulseBefore 1.5s infinite linear;
			animation-delay: 0s;
		  }
		  
		  .dot-pulse::after {
			box-shadow: 10010px 0 0 -5px #5FD770;
			animation: dotPulseAfter 1.5s infinite linear;
			animation-delay: .5s;
		  }
		  
		  @keyframes dotPulseBefore {
			0% {
			  box-shadow: 9989px 0 0 -5px #5FD770;
			}
			30% {
			  box-shadow: 9989px 0 0 2px #5FD770;
			}
			60%,
			100% {
			  box-shadow: 9989px 0 0 -5px #5FD770;
			}
		  }
		  
		  @keyframes dotPulse {
			0% {
			  box-shadow: 9999px 0 0 -5px #5FD770;
			}
			30% {
			  box-shadow: 9999px 0 0 2px #5FD770;
			}
			60%,
			100% {
			  box-shadow: 9999px 0 0 -5px #5FD770;
			}
		  }
		  
		  @keyframes dotPulseAfter {
			0% {
			  box-shadow: 10010px 0 0 -5px #5FD770;
			}
			30% {
			  box-shadow: 10010px 0 0 2px #5FD770;
			}
			60%,
			100% {
			  box-shadow: 10010px 0 0 -5px #5FD770;
			}
		  }
	}
	.tbl-list {
		width: 100%;
		.tbl-header {
			// background-color: $grid-header;
			// color: $tbl-header-txt;
			font-family: roboto-regular;
			// text-transform: uppercase;
			font-size: 11px;
			td>div {
				text-align: center;
				height: 40px;
				padding: 0 10px;
				border-radius: 5px;
				margin: 0px 5px 0px 0px;
				width: 100%;
				padding: 8px 10px;
				background-color: #f8f9fb;
			}
		}

		.tbl-body {
			width: 100%;
			.tbl-row {
				// font-size: 14px;
				padding: 15px;
				color: $table-body-text;
				border-bottom: 1px solid rgba(187, 187, 187, .5);
				td {
					text-align: left;
					padding-left: 10px;
				}
				&:hover .fa-pencil-alt {
					opacity: 1;
				}
			}
			.inactive-row {
				color: $inactive;
				font-style: italic;
			}
		}
		.status {
			width: 60px;
			&.active {
				color: $active;
			}
		}
		.name {
			min-width: 200px;
			font-family: roboto-medium;
		}
		.type {
			min-width: 200px;
		}
		.address-text {
			overflow: hidden;

			.line-brk {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		.address {
			word-break: break-all;
			//  -width: 300px;
		}
	}
		// .date {
			// max-width: 150px;
		// }
		.creation-date{
			padding-left: 10px;
		}
		.edit {
			width: 30px;
			height: 35px;
			cursor: pointer;
			line-height: 34px;
			.fa-pencil-alt {
				opacity: 0;
			}
		}
	}
}
}