@import '../../variables.scss';

.sort-container {
	display: flex;
	place-content: space-between;
	// margin-left: 5px;
	align-items: center;
	// justify-content: center;
	.text-container {
		cursor: pointer;
		white-space: nowrap;
		color: #B1B1B1
	}
	.icon-cotainer {
		line-height : 14px;
		justify-content: right;
		.icon-sort {
			margin-left: 5px;
			cursor: pointer;
			height: 12px;
			width: 8px;
			.unsorted {
				color: $cancel-btn-color;
			}
		}
	}
}

// .icon_sort .arrow-up.sorted {
// 	background-image: url('../images/sort-half-fill.png');
// 	background-repeat: no-repeat;
// 	background-size: contain;
// 	height: 12px;
// 	width: 16px;
// 	display: inline-block;
// }
// .icon_sort .arrow-up.unsorted {
// 	background-image: url('../images/sort-half-unfill.png');
// 	background-repeat: no-repeat;
// 	background-size: contain;
// 	transform: rotate(0deg);
// 	height: 10px;
// 	width: 16px;
// 	display: inline-block;
// 	background-position: 0 -2px;
// }

// .icon_sort .arrow-bottom.sorted {
// 	background-image: url('../images/sort-half-fill.png');
// 	background-repeat: no-repeat;
// 	background-size: contain;
// 	transform: rotate(180deg);
// 	height: 12px;
// 	width: 16px;
// 	display: inline-block;
// }
// .icon_sort .arrow-bottom.unsorted {
// 	background-image: url('../images/sort-half-unfill.png');
// 	background-repeat: no-repeat;
// 	background-size: contain;
// 	transform: rotate(180deg);
// 	height: 10px;
// 	width: 16px;
// 	display: inline-block;
// }
