@import '../../variables.scss';

.addcustomer-modal {
	// width: 50%;
	// height: 719px;
	max-width: none !important;
	width: 700px !important;

	.modal-content {
		.modal-header {
			border: none;
			color: $header-text;
			font-family: roboto-light;
			font-size: 30px;
			font-weight: 300;
			justify-content: center;
			padding: 30px;
		}

		.modal-body {
			.nav-tabs-update {
				border-bottom: none;
				height: 38px;
				border-radius: 5px;
				justify-content: center;

				.nav-link {
					font-family: roboto-medium;
					font-size: 12px;
					background-color: $modal-footer-bg;
					text-align: center;
					padding: 10px 30px;
					width: 300px;
					color: $active-nav-link;
					// text-transform: uppercase;

					&.active {
						background-color: $active-nav-link;
						color: $white;
						font-family: roboto-bold;
					}

					&:first-child {
						border-radius: 5px 0 0 5px
					}

					&:last-child {
						border-radius: 0px 5px 5px 0px;
					}
				}

				.nav-link:focus {
					background-color: $active-nav-link;
					color: $white;
				}
			}

			.nav-tabs-add {
				border-bottom: none;
				height: 38px;
				border-radius: 5px;
				justify-content: center;

				.nav-link {
					font-family: roboto-medium;
					font-size: 12px;
					background-color: $modal-footer-bg;
					text-align: center;
					padding: 10px 30px;
					width: 600px;
					color: $active-nav-link;
					// text-transform: uppercase;
					border-radius: 5px;

					&.active {
						background-color: $active-nav-link;
						color: $white;
						font-family: roboto-bold;
					}
				}

				.nav-link:focus {
					background-color: $active-nav-link;
					color: $white;
				}

			}

			.modal-tabs {

				.tab-content {
					height: 12em;
					padding: 0px 40px;
					margin: 40px 0;
					justify-content: center;

					.userDetail {
						padding: 0 10px;
					}

					.section {
						justify-content: center;
						margin-bottom: 20px;

						.labels {
							display: flex;
						}

						.inputfields {
							display: flex;
						}

						label {
							color: $modal-label-color;
							font-family: roboto-medium;
							font-size: 12px;
							line-height: 16px;
							outline: none;
							width: 50%;
							// text-transform: uppercase;
						}

						input,
						textarea {
							box-sizing: border-box;
							margin-right: 2em;
							border: 1px solid rgba(156, 156, 156, .5);
							border-radius: 5px;
							background-color: $white;
							box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
							height: 40px;
							width: 50%;
							padding-left: 10px;
						}

						textarea {
							width: 100%;
							margin-right: 0;
							height: 75px;
							padding: 5px 10px;
						}

						input:focus,
						textarea:focus {
							outline: none;
						}

						.delete_field {
							text-align: left;
							margin-bottom: 30px;
						}

						.deletecustomer {
							height: 16px;
							width: 62px;
							color: $error-red;
							font-family: roboto-regular;
							font-size: 12px;
							letter-spacing: 0;
							line-height: 16px;
							text-align: center;
							text-decoration: underline;
							cursor: pointer;
						}

						.org-label {
							width: 30%;
						}

						.custom-search-select {
							width: 55%;
							height: 32px;
							display: inline-block;

							&:hover {
								border: none;
							}
						}
					}

					.setting {
						padding-bottom: 42px;
						border-bottom: 0.5px solid $gray-border;

						.custom-status-select {
							width: 187px;
							height: 32px;
							display: inline-block;
							margin-left: 80px;
						}
					}

					.error_field {
						span {
							color: $error-red;
						}
					}
				}
			}
		}

		.modal-footer {
			display: flex;
			// height: 155px;
			background-color: $modal-footer-bg;
			// align-items: center;
			// justify-content: center;
			padding: 0px 60px;

			.buttons {
				box-sizing: border-box;
				// height: 58px;
				// width: 250px;
				// border-radius: 29px;
				// text-transform: uppercase;
				font-size: 12px;
				font-weight: roboto-bold;
			}
		}
	}

}