@import '../../variables.scss';

#user-profile-container{
	padding: 20px 4% 0;

.user-profile {
    min-height: 80vh;
    min-width: 800px;
    padding: 30px 40px;
    width: 100%;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 0 14px 0 rgba(31, 53, 77, 0.2);

    .section {
        padding-bottom: 35px;
        // border: 0.5px solid;

        .sub-title {
            font-size: 21px;
            line-height: 30px;
            font-family: "roboto-bold";
            color: $color-grey;
            padding-bottom: 12px;

            .edit {
                color: #00B5E0;
                font-size: 12px;
                padding-left: 10px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .detail-container {
        text-align: left;
        font-size: 12px;
        height: 60px;

        .detail-label {
            font-family: roboto-medium;
            color: #9C9C9C;;
            padding-bottom: 10px;
            
        }

        .detail-label>div,
        .detail-value>div {
            display: inline-block;
        }

        .detail-value {
            font-size: 16px;
            font-family: 'roboto-regular';
            color: $tbl-label-color;
        }

        .fname {
            min-width: 150px;
        }

        .lname {
            min-width: 150px;
        }

        .email {
            min-width: 250px;
        }

        .phone {
            min-width: 150px;
        }
    }

    .edit-detail-container {
        text-align: left;
        font-size: 12px;
        width: 100%;
        border: 1px solid #D0D0D0;
        box-shadow: 2px 7px 10px 0 rgba(0,0,0,0.23);
        // height: 120px;

        .edit-detail-label>div,
        .edit-detail-value>div {
            display: inline-block;
        }

        .detail-body {
            background-color: #ffffff;
            padding: 25px 25px;

            .edit-detail-label{
                padding-bottom: 5px;
                color: #A0A0A0;
                .email {
                    font-size: 12px;
                }
                .phone {
                    font-size: 12px;
                }
            }

            input[type=text] {
                &:focus{
                    outline: none;
                }
                box-sizing: border-box;
                border: 1px solid rgba(156, 156, 156, 0.5);
                border-radius: 3px;
                background-color: #ffffff;
                box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
                height: 30px;
                width: 23%;
                padding-left: 10px;
                margin-right: 2%;
                font-size: 14px;
            }

            .fname {
                // min-width: 230px;
                min-width: 25%;
            }

            .lname {
                // min-width: 230px;
                min-width: 25%;
            }

            .email {
                // min-width: 250px;
                min-width: 30%;
                font-size: 14px;
            }

            .phone {
                min-width: 20%;
                font-size: 14px;
            }
        }

        .error_field {
            height: 10px;
            padding: 10px 0px 0px;
            span {
              color: $error-red;
            }
          }
        .detail-footer {
            background-color: #F1F1F1;
            height: 70px;
            padding: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 30px;

            .edit-btn {
                width: 150px;
                height: 40px;
                font-size: 12px;
            }

            .cancel-btn {
                &:focus, &:active{
                    outline: none;
                    border: none;
                }
                background-color: transparent;
                color: #9C9C9C;
                margin-right: 10px;
            }
        }
    }

    .values {
        color: #4A4A4A;
        font-size: 16px;
        font-family: roboto-regular;
    }

    .password-btn {
        width: 183px;
        margin-top:10px
    }
}

}